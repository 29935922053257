
let config = {
  channel: {
    10:'全部',
    11: '企业拓展',
    12: '社区拓展',
    13: '转介绍',
    14: '地推',
    15: '异业合作',
    16: '自媒体',
    17: '商圈商铺',
    18: '路口地铁'
  },
  product: {
    20:'全部',
    21: '自助',
    22: '私教',
    23: '团课'
  },
  level: {
    100:'全部',
    101: 'S(高意向必买)',
    102: 'A(7天内到馆)',
    103: 'B(7天后到馆)',
    104: 'C(不确定/羊毛党)'
  },
  bodyTest: {
    110:'全部',
    111: '已体测',
    112: '未体测'
  },
  age: {
    120:'全部',
    121: '15-19岁',
    122: '20-29岁',
    123: '30-39岁',
    124: '40-49岁',
    125: '50岁以上'
  },
  job: {
    130:'全部',
    131: '学生',
    132: '白领',
    133: '个体户',
    134: '家庭主妇',
    135: '服务业'
  },
  fitnessNeed: {
    140:'全部',
    141: '增肌',
    142: '减脂',
    143: '塑形',
    144: '普拉提'
  },
  duration: {
    150:'全部',
    151: '25分钟以下',
    152: '25-60分钟',
    153: '60-90分钟',
    154: '90分钟以上'
  },
  experience: {
    160:'全部',
    161: '自助',
    162: '私教',
    163: '团操'
  }
}

function getTagJson() {
  let json = {}
  Object.values(config).map(i => {
    json = { ...json, ...i }
  })
  return json
}


export { config, getTagJson }

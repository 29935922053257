<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="refreshFun" pulling-text="继续往下拉" loosing-text="该放手了，我要刷新啦..."
      :style="{ height: 'calc(100vh - ' + listHeight + 'px)', overflow: 'scroll' }" loading-text="拼命加载中"
      :pull-distance="100">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getData" :immediate-check="false">
        <div class="chose">
          <span class="search" @click="failShowFun(true)" v-if="menuIndex == 0 && isMember"><van-icon
              name="search" />绑定失败查询</span>
          <span class="search" @click="showCluePop" v-if="routerMenuIndex == 0 && active == 2 && isClue">新增线索</span>
          <span class="f24 row-between-center " style="width: 70%;" v-if="menuIndex != 0 && (isMember || ispublicSea)">
            <span style="color: #3665BD;" @click="allChangeBind">一键转绑</span>
            <span style="color: #3665BD;" @click="memberTagShow = true">获客标签</span>
            <span class="red">（当前人数：{{ total }}）</span>
          </span>
          <span></span>
          <span class="chose-btn" @click="choseMemberFun">
            <span>筛选会员</span><van-icon name="arrow-down" />
          </span>
        </div>
        <div v-if="ispublicSea" class="cline1">公海用户为扫码但未购买的，或者解绑后未再次被绑定的会员。</div>
        <div class="member-box iphonex-pb">
          <div v-for="i in list" class="menber-item">
            <div class="flex">
              <img class="avt" v-if='!isClue' :src="i.headImg" alt="">
              <div class="pub_onefull">
                <p class="line1">
                  <span v-if="isClue">{{ i.nickName }}</span>
                  <template v-else>
                    <span class="name ellipsis-1">{{ i.remarks || i.userName || i.nickName }}</span>
                    <span class="detail" v-if="isMember">
                      <span @click="detailShowFun(i.bindId)">消费金额(仅转化) ¥{{ i.payAmount || '0.00' }}<van-icon
                          name="question-o" class="ml3" /></span>
                    </span>
                  </template>
                </p>
                <p class="line2">
                  <span>{{ i.mobile }}</span>
                  <span class="status" v-if='isMember'>
                    <span v-if="i.indoorFlag == 1"><van-icon name="checked" />已进馆</span>
                    <span v-else class="c2"><i class="icon" />未进馆</span>
                    <span class="ml20" v-if="i.invertFlag == 1"><van-icon name="checked" />已转化</span>
                    <span v-else class="ml20 c2"><i class="icon" />未转化</span>
                  </span>
                </p>
                <p class="bind" v-if="isMember || ispublicSea">
                  <span class="do" @click="changeBind(i)"
                    v-if="menuIndex != 0 && i.unbindFlag != 1 && i.invertFlag != 1">修改绑定<van-icon name="arrow" /></span>
                  <span v-if="i.unbindFlag == 0 && i.validDay != null && i.validDay >= 0" class="flex">剩余绑定期：
                    <span style="color:#F03C18" class="row-start-center">
                      {{ i.validDay }}天 </span>
                  </span>
                  <span v-else class="row-start-center" style="color:#F03C18">
                    <span class="mt1">{{ i.statusStr }}</span>
                  </span>
                </p>
                <p class="tag">
                  <span class="new" v-if="i.userType == 1">新会员</span>
                  <span class="old" v-if="i.userType == 2">老会员</span>
                  <span v-for="tag in i.tagList">{{ tagJson[tag] }}</span>
                  <span v-if="isMember" @click="showTagPop(i)"><van-icon name="edit" />标签备注</span>
                  <span v-if="isClue" @click="editClue(i)"><van-icon name="edit" />编辑</span>
                </p>
                <p class="gift" v-if='isMember || ispublicSea'>礼包领取情况：{{ i.receiveGift || '无' }}</p>
                <p class="line3">
                  <span class="pub_onefull" v-if='!isClue'>{{ ispublicSea ? '进入' : (i.removeBeforeStatus == 1 ? '扫码' :
                    '绑定') }}时间：{{ i.bindTime }}</span>
                  <span class="pub_onefull" v-else>创建时间：{{ i.createDate }}</span>
                  <span class="flex" v-if="menuIndex != 0">
                    拓展专员：<span class="ellipsis-1" style="max-width: 50px;">{{ i.saleUserName }}</span>
                  </span>
                </p>
                <template v-if="isRemove">
                  <p class="line3 mt3">
                    <span class="pub_onefull">移出时间：{{ i.removeTime }}</span>
                  </p>
                  <p class="line3 mt3" style="color:#F03C18">
                    <span class="pub_onefull">移出前状态：{{ i.removeBeforeStatusStr }}</span>
                  </p>
                  <p class="line3 mt3" style="color:#F03C18">
                    <span class="pub_onefull">移出原因：{{ i.removeReason }}</span>
                  </p>
                  <p class="line3 mt3" style="color:#267DFF">
                    <span class="pub_onefull">消费金额：{{ i.amount }}</span>
                    <span class="pub_onefull">消费金额(仅转化)：{{ i.invertAmount }}</span>
                  </p>
                </template>
                <p class="line4 mt5" v-if='i.firstInDate'>进馆时间：{{ i.firstInDate }}</p>
                <p class="line4 mt5" v-if='isClue'>备注：{{ i.remarks || '-' }}</p>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="detailShow" round position="bottom" class="pop detail-box" closeable safe-area-inset-bottom>
      <p class="title">消费金额明细(仅转化)</p>
      <div class="d-box">
        <div class="border">
          <p>全渠道</p>
          <div class="row-between-center">
            <div>
              <p>超鹿月卡</p>
              <p>￥<span>{{ detailInfo.superDeerCardAmount || 0 }}</span></p>
              <p>{{ detailInfo.superDeerCardNum || 0 }}张</p>
            </div>
            <div>
              <p>包月私教卡</p>
              <p>￥<span>{{ detailInfo.perCardAmount || 0 }}</span></p>
              <p>{{ detailInfo.perCardNum || 0 }}张</p>
            </div>
            <div>
              <p>私教课包</p>
              <p>￥<span>{{ detailInfo.perPackageAmount || 0 }}</span></p>
              <p>{{ detailInfo.perPackageNum || 0 }}份</p>
            </div>
            <div>
              <p>礼品卡</p>
              <p>￥<span>{{ detailInfo.cardAmount || 0 }}</span></p>
              <p>{{ detailInfo.cardNum || 0 }}份</p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-box">
        <div>
          <p>用拓渠道</p>
          <div class="row-between-center">
            <div>
              <p>超鹿月卡</p>
              <p>￥<span>{{ detailInfo.bdsuperDeerCardAmount || 0 }}</span></p>
              <p>{{ detailInfo.bdsuperDeerCardNum || 0 }}张</p>
            </div>
            <div>
              <p>包月私教卡</p>
              <p>￥<span>{{ detailInfo.bdperCardAmount || 0 }}</span></p>
              <p>{{ detailInfo.bdperCardNum || 0 }}张</p>
            </div>
            <div>
              <p>私教课包</p>
              <p>￥<span>{{ detailInfo.bdperPackageAmount || 0 }}</span></p>
              <p>{{ detailInfo.bdperPackageNum || 0 }}份</p>
            </div>
            <div>
              <p>礼品卡</p>
              <p>￥<span>{{ detailInfo.bdcardAmount || 0 }}</span></p>
              <p>{{ detailInfo.bdcardNum || 0 }}份</p>
            </div>
          </div>
        </div>

      </div>
    </van-popup>
    <van-popup v-model="failShow" round position="bottom" class="pop" closeable safe-area-inset-bottom>
      <p class="title">绑定失败查询</p>
      <div class="fail-box">
        <van-list v-model="failLoading" :finished="failFinished" finished-text="没有更多了" @load="failShowFun"
          :immediate-check="false">
          <div class="fail-item" v-for="i in failList">
            <img class="avt" :src="i.headImg" alt="">
            <div class="pub_onefull">
              <p class="fw6">{{ i.mobile }}</p>
              <p class="f22">{{ i.bindTime }}</p>
              <p class="f24 blue">{{ i.reason }}</p>
            </div>
          </div>
        </van-list>
      </div>
    </van-popup>
    <van-popup v-model="tagShow" round position="bottom" class="pop" closeable safe-area-inset-bottom>
      <p class="title">标签和备注</p>
      <div class="chose-box tag-box">
        <div class="chose-item">
          <p class="c-t">备注</p>
          <div>
            <van-field class="input" v-model="tagInfo.remarks" maxlength="20" placeholder="在此输入用户备注名称" />
            <p class="nick">昵称：{{ tagInfo.userName }}</p>
          </div>
        </div>
        <p class="pl32 f28 fw6">标签</p>
        <tagVue ref="tagRef" :choseTag="tagInfo.tagList" />
        <div class="chose-item">
          <p class="c-t">未转化原因</p>
          <div>
            <van-field class="input" v-model="tagInfo.unInvertReason" maxlength="50" placeholder="在此输入未转化原因" />
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">回访结果</p>
          <div>
            <van-field class="input" v-model="tagInfo.followUpResult" maxlength="50" placeholder="在此输入回访结果" />
          </div>
        </div>
      </div>
      <div class="btn-box">
        <span @click="rest">重置</span>
        <span class="confirm" @click="tagConfirm">确认</span>
      </div>
    </van-popup>
    <van-popup v-model="clueShow" round position="bottom" class="pop" closeable safe-area-inset-bottom>
      <p class="title">{{ clueType == 'add' ? '添加' : '编辑' }}线索</p>
      <div class="chose-box">
        <div class="chose-item">
          <p class="c-t">手机号/微信 <span>*</span></p>
          <van-field class="input" v-model="clueInfo.mobile" maxlength="11" :disabled="clueType != 'add'"
            placeholder="在此输入用户手机号/微信" />
        </div>
        <div class="chose-item">
          <p class="c-t">昵称 <span>*</span></p>
          <van-field class="input" v-model="clueInfo.nickName" maxlength="20" placeholder="在此输入用户昵称" />
        </div>
        <div class="chose-item">
          <p class="c-t">备注</p>
          <van-field class="input" v-model="clueInfo.remarks" placeholder="在此输入备注" rows="5" autosize type="textarea" />
        </div>
        <div class="chose-item">
          <p class="c-t">意向等级（到店体验时间）<span>*</span></p>
          <div class="c-items">
            <span v-for="(i, key) in leaveList" v-if="key != 0" :class="clueInfo.leaveTag == key ? 'act' : ''"
              @click="changeFun('clueInfo', 'leaveFlag', key)">{{ i }}</span>
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">私教意向</p>
          <div class="c-items">
            <span :class="clueInfo.personFlag ? 'act' : ''" @click="changeFun('clueInfo', 'personFlag')">有私教意向</span>
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">是否绑定</p>
          <div class="c-items">
            <span :class="clueInfo.bindFlag ? 'act' : ''" @click="changeFun('clueInfo', 'bindFlag')">已绑定</span>
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">是否进馆</p>
          <div class="c-items">
            <span :class="clueInfo.indoorFlag ? 'act' : ''" @click="changeFun('clueInfo', 'indoorFlag')">已进馆</span>
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">是否转化</p>
          <div class="c-items">
            <span :class="clueInfo.invertFlag ? 'act' : ''" @click="changeFun('clueInfo', 'invertFlag')">已转化</span>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <span @click="resetClueFun">重置</span>
        <span class="confirm" @click="clueConfirm">确认</span>
      </div>
    </van-popup>
    <van-popup v-model="changeShow" round position="bottom" class="change-pop" safe-area-inset-bottom>
      <p class="title">会员：{{ changeMember.remarks || changeMember.userName }}</p>
      <div @click="changeShowFun">转绑</div>
      <div v-if="isMember" @click="changeShow3 = true">解绑</div>
      <div class="gray"></div>
      <div @click="changeShow = false">取消</div>
    </van-popup>
    <van-popup v-model="changeShow2" round position="bottom" class="pop chose-t" closeable safe-area-inset-bottom
      @close="closeChangeFun">
      <p class="title">选择团队成员</p>
      <p class="tips">会员 <span>{{ changeMember.remarks || changeMember.userName }}</span> 转给 > 拓展专员 <span>{{
        changeBindUser.userName || '请选择' }}</span> </p>
      <div class="chose-box" v-if="menuIndex == 2 || data.saleTeamId == -1">
        <div class="chose-item" v-for="i in teamMemberList">
          <p class="c-t">{{ i.saleTeamName }}</p>
          <div class="c-items ">
            <span @click="changeBindUserFun(i2)" class="ellipsis-1" v-if="i2.workStatus == 0"
              :class="changeBindUser.userId == i2.userId ? 'act' : ''" v-for="i2 in i.memberList">{{ i2.userName }}</span>
          </div>
        </div>
      </div>
      <div class="chose-box" v-else>
        <div class="chose-item">
          <div class="c-items">
            <span v-for="(i, key) in teamList" :class="changeBindUser.userId == i.userId ? 'act' : ''"
              @click="changeBindUserFun(i)" class="ellipsis-1">{{ i.userName }}</span>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <span @click="closeChangeFun">取消</span>
        <span class="confirm" @click="confirmChange">确认</span>
      </div>
    </van-popup>
    <van-popup v-model="changeShow3" round class="tips-pop" safe-area-inset-bottom @close="closeUnBind">
      <p class="title">解绑提示</p>
      <p>会员<span>“{{ changeMember.remarks || changeMember.userName }}”</span> 将从拓展专员<span>“{{ changeMember.saleUserName
      }}”</span>解绑</p>
      <p>注：30天内或连续两次无法绑定同一拓展人员</p>
      <div class="btns">
        <span @click="closeUnBind">我再想想</span>
        <span @click="unBindFun">确认解绑</span>
      </div>
    </van-popup>
    <van-popup v-model="changeShow4" round position="bottom" class="pop chose-t" safe-area-inset-bottom
      @close="closeChangeFun">
      <p class="title">一键转绑</p>
      <p class="p32 f24">选择成员</p>
      <div class="chose-box" v-if="menuIndex == 2 || data.saleTeamId == -1">
        <div class="chose-item" v-for="i in teamMemberList">
          <p class="c-t">{{ i.saleTeamName }}</p>
          <div class="c-items ">
            <span @click="changeBindUserFun(i2)" class="ellipsis-1" v-if="i2.workStatus == 0"
              :class="changeBindUser.userId == i2.userId ? 'act' : ''" v-for="i2 in i.memberList">{{ i2.userName }}</span>
          </div>
        </div>
      </div>
      <div class="chose-box" v-else>
        <div class="chose-item">
          <div class="c-items">
            <span v-for="(i, key) in teamList" :class="changeBindUser.userId == i.userId ? 'act' : ''"
              @click="changeBindUserFun(i)" class="ellipsis-1">{{ i.userName }}</span>
          </div>
        </div>
      </div>
      <p class="ctips">注: 单次仅支持最多80人成功转绑，可转绑的会员为在绑定期内且未转化的。</p>
      <div class="btn-box">
        <span @click="closeChangeFun">取消</span>
        <span class="confirm" @click="allChangeBindFun">确认</span>
      </div>
    </van-popup>
    <choseMemberVue :teamList="teamList" :menuIndex="menuIndex" :active="active" ref="choseMemberRef"
      @choseMemberConfirm="choseMemberConfirm" :teamMemberList="teamMemberList" />
    <van-popup v-model="memberTagShow" round position="bottom" class="pop member-tag-pop" closeable
      safe-area-inset-bottom>
      <p class="title">编辑获客标签</p>
      <div class="m-box">
        <p @click="editTagShow = true">
          <van-icon class="mr24" :name="baseImgUrl + 'add.png'" />
          <span class="pub_onefull">新增标签</span>
        </p>
        <p v-for="i in memberTagList">
          <van-icon @click="delTag(i)" class="mr24" :name="baseImgUrl + 'del.png'" />
          <span class="pub_onefull">{{ i.name }}</span>
          <van-icon @click="editTagName(i)" :name="baseImgUrl + 'edit.png'" />
        </p>
      </div>
      <div class="btn-box">
        <span class="confirm" @click="memberTagShow = false">保存</span>
      </div>
    </van-popup>
    <van-popup v-model="editTagShow" round position="bottom" class="pop edit-tag-pop" safe-area-inset-bottom>
      <div class="e-title">
        <van-icon name="cross" @click="closeTagShow" />
        <span class="f32 fw6">{{editTagItem.sourceId?'编辑':'新增'}}获客标签</span>
        <span class="save" :class="tagName.length > 1 ? 'can' : ''" @click="saveEditTag">保存</span>
      </div>
      <div class="chose-box tag-box">
        <div class="chose-item">
          <div>
            <van-field class="input" v-model="tagName" placeholder="请输入获客标签名称" rows="5" autosize maxlength="20" type="textarea" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import choseMemberVue from './choseMember.vue'
import tagVue from './tag.vue'
import { getTagJson } from './config'
import { getParam } from '@/lib/utils'
let infoObj = { mobile: '', leaveTag: '', personFlag: false, leaveTag: false, bindFlag: false, indoorFlag: false, invertFlag: false }
export default {
  components: { choseMemberVue, tagVue },
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/user-expansion-crm/',
      isMember: true,
      isClue: false,
      ispublicSea: false,
      isRemove: false,
      data: {},
      refreshing: false,
      loading: false,
      finished: false,
      page: 1,
      list: [],
      total: 0,
      tagJson: {},
      emptyHeight: 0,
      routerMenuIndex: 0,
      // 搜索
      show: false,
      // 消费详情
      detailShow: false,
      detailInfo: {},
      // 绑定失败查询
      failShow: false,
      failList: [],
      failLoading: false,
      failFinished: false,
      failPage: 1,
      tagList: { 1: 'S（2天内）', 2: 'A（3-4天）', 3: 'B（5-6天）', 4: 'C（7天以上）', 101: '有私教意向' },
      // 标签和备注
      leaveList: ['全部', 'S（2天内）', 'A（3-4天）', 'B（5-6天）', 'C（7天以上）'],
      tagShow: false,
      tagInfo: { personFlag: false, leaveTag: '', remark: '' },
      resetTag: {},
      // 线索
      clueShow: false,
      clueInfo: JSON.parse(JSON.stringify(infoObj)),
      resetClue: {},
      clueType: 'add',
      // 解转绑
      changeShow: false,
      changeShow2: false,
      changeShow3: false,
      changeMember: {},
      changeBindUser: {},
      teamMemberList: [],
      changeShow4: false,
      // 获客标签
      memberTagShow: false,
      memberTagList: [],
      editTagShow: false,
      tagName: '',
      editTagItem: {}
    }
  },
  props: ['menuIndex', 'active', 'listHeight', 'teamList'],
  watch: {
    active() {
      this.getType()
      this.$refs.choseMemberRef.reset()
    }
  },
  mounted() {
    this.getType()
    this.tagJson = this.getTagJson()
    this.routerMenuIndex = getParam().menuIndex
    this.getTeamMemberList()

  },
  methods: {
    getTagJson,
    getType() {
      this.isMember = this.active == 1 || this.active == 4
      this.isClue = this.active == 2 || this.active == 6
      this.ispublicSea = this.active == 7
      this.isRemove = this.active == 8
    },
    getData(d) {
      if (d) {
        this.data = d
        this.list = []
        this.page = 1
      }
      let d2 = { pageSize: 10, pageNum: this.page, ...this.data }, url
      this.isMember ? url = "/userExpansionBing/query/getMemberList" : this.ispublicSea ? url = "/userExpansionBing/query/pagePublicSea" : this.isClue ? url = "/userExpansionBing/query/pageClue" : url = '/userExpansionBing/query/pageRemoveRecord'
      this.$axios.post(this.baseURLApp + url, d2)
        .then((res) => {
          this.refreshing = false
          this.loading = false
          this.page++
          this.list = this.list.concat(res.data.records)
          this.total = res.data.total
          if (this.routerMenuIndex != 0) {
            this.getMemberTagList()
          }
          if (res.data.records.length < d2.pageSize) {
            this.finished = true
          }
        })

    },
    detailShowFun(bindId) {
      this.$axios.post(this.baseURLApp + "/userExpansionBing/query/getInvertDetail", { bindId })
        .then((res) => {
          this.detailInfo = res.data
          this.detailShow = true
        })
    },
    // 绑定失败查询
    failShowFun(type) {
      let d = this.data
      if (type) {
        this.failList = []
        this.failPage = 1
        this.failShow = true
      }
      d = { ...d, pageSize: 10, pageNum: this.failPage }
      this.$axios.post(this.baseURLApp + "/userExpansionBing/query/pageFailBind", d)
        .then((res) => {
          this.failLoading = false
          this.failPage++
          this.failList = this.failList.concat(res.data.records)
          if (res.data.records.length < 10) {
            this.failFinished = true
          }
        })
    },
    refreshFun(type) {
      this.list = []
      this.page = 1
      this.finished = false
      this.loading = true
      this.getData()
      if (!type) {
        this.$emit('refresh')
      }
    },
    // 会员查询
    choseMemberFun() {
      this.$refs.choseMemberRef.showFun()
    },
    choseMemberConfirm(d) {
      this.data = { ...this.data, ...d }
      this.refreshFun(true)
    },
    // 标签和备注、线索
    changeFun(infoName, tagName, key) {
      if (tagName == 'leaveFlag') {
        this[infoName].leaveTag = key
        return
      }
      this[infoName][tagName] = !this[infoName][tagName]
    },
    rest() {
      this.tagInfo = JSON.parse(JSON.stringify(this.resetTag))
    },
    showTagPop(i) {
      this.tagInfo = { ...this.tagInfo, ...i }
      this.resetTag = JSON.parse(JSON.stringify(this.tagInfo))
      this.tagShow = true
    },
    tagConfirm() {
      const { remarks, bindId, followUpResult, unInvertReason } = this.tagInfo
      if (/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g.test(remarks)) {
        this.$toast('备注名不能有Emoji！');
        return
      }
      if (/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g.test(followUpResult)) {
        this.$toast('回访结果不能有Emoji！');
        return
      }
      if (/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g.test(unInvertReason)) {
        this.$toast('未转化原因不能有Emoji！');
        return
      }
      let tagList = this.$refs.tagRef.getTagList()
      let d = { bindId, remarks, followUpResult, unInvertReason, tagList }
      this.$axios.post(this.baseURLApp + "/userExpansionBing/other/make/tag", d)
        .then((res) => {
          this.tagShow = false
          this.list.map((i, index) => {
            if (i.bindId == bindId) {
              this.list[index] = { ...i, remarks, tagList, followUpResult, unInvertReason }
            }
          })
        })
    },
    // 解转绑
    changeBind(i) {
      this.changeMember = i
      this.changeShow = true
    },
    allChangeBind() {
      this.changeShow4 = true
    },
    changeBindUserFun(i) {
      this.changeBindUser = i
    },
    allChangeBindFun() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      this.$axios.post(this.baseURLApp + '/userExpansionBing/batchChangeBind', { ...this.data, changeBindDateSource: this.ispublicSea ? 'PUBLIC_SEA' : 'TEAM_MEMBER', receiveUserId: this.changeBindUser.userId }).then((res) => {
        this.$toast.clear()
        this.$toast(`已成功转绑${res.data.successNum}位会员转给拓展专员${this.changeBindUser.userName}，还剩余${this.total - res.data.successNum}位会员未转绑。`)
        this.getData(this.data)
        this.closeChangeFun()
      })
    },
    closeChangeFun() {
      this.changeShow2 = false
      this.changeShow = false
      this.changeBindUser = {}
      this.changeShow4 = false
    },
    confirmChange() {
      this.$axios.post(this.baseURLApp + '/userExpansionBing/manualChangeBind', {
        bindId: this.changeMember.bindId,
        userId: this.changeMember.userId,
        receiveUserId: this.changeBindUser.userId
      }).then((res) => {
        this.$toast('转绑成功')
        if (this.ispublicSea) {
          let index = this.list.findIndex((item) => item.bindId == this.changeMember.bindId)
          this.list.splice(index, 1)
        } else {
          this.getNew()
        }
        this.closeChangeFun()
      })
    },
    closeUnBind() {
      this.changeShow3 = false
      this.changeShow = false
    },
    unBindFun() {
      this.$axios.post(this.baseURLApp + '/userExpansionBing/manualUnbind', {
        bindId: this.changeMember.bindId,
        userId: this.changeMember.userId,
      }).then((res) => {
        this.$toast('解绑成功')
        this.getNew()
        this.closeUnBind()
      })
    },
    getNew() {
      let url = this.isMember ? '/userExpansionBing/query/getMemberList' : '/userExpansionBing/query/pagePublicSea'
      this.$axios.post(this.baseURLApp + url, {
        bindId: this.changeMember.bindId,
        pageSize: 10, pageNum: 1
      }).then((res) => {
        let index = this.list.findIndex((item) => item.bindId == this.changeMember.bindId)
        this.list.splice(index, 1, res.data.records[0])
      })
    },
    async changeShowFun() {
      this.changeShow2 = true
    },
    async getTeamMemberList() {
      let saleTeamIdList = this.teamList.filter((item) => (item.saleTeamId != -1)).map((item) => item.saleTeamId)
      let res = await this.$axios.post(this.baseURLApp + '/userExpansionBing/query/listSaleMember', {
        saleTeamIdList
      })
      this.teamMemberList = res.data
    },
    // 新增，修改线索
    showCluePop() {
      this.clueInfo = JSON.parse(JSON.stringify(infoObj))
      this.clueType = 'add'
      this.clueShow = true
    },
    getClueD() {
      const { nickName, mobile, leaveTag, bindFlag, indoorFlag, invertFlag, personFlag, remarks } = this.clueInfo
      if (!nickName || !mobile || !leaveTag) {
        this.$toast('请填写必填项');
        return false
      }
      if (/(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g.test(nickName)) {
        this.$toast('备注名不能有Emoji！');
        return false
      }
      let d = {
        nickName, mobile, remarks,
        userId: getParam().userId,
        activityId: this.data.activityId,
        bindFlag: bindFlag ? 1 : 0,
        indoorFlag: indoorFlag ? 1 : 0,
        invertFlag: invertFlag ? 1 : 0,
        tagList: [leaveTag]
      }
      personFlag ? d.tagList.push('101') : ''
      return d
    },
    clueConfirm() {
      let d = this.getClueD(), url = '/userExpansionBing/other/addClue', str = '新增'
      if (this.clueType == 'edit') {
        url = '/userExpansionBing/other/updateClue'
        str = '编辑'
        d.clueId = this.clueInfo.clueId
      }
      if (d) {
        this.$axios.post(this.baseURLApp + url, d).then((res) => {
          this.$toast(str + '成功');
          this.clueShow = false
          if (this.clueType == 'edit') {
            let index = this.list.findIndex((item) => item.clueId == this.clueInfo.clueId)
            this.list.splice(index, 1, { ...this.list[index], ...d })
          } else {
            this.page = 1
            this.list = []
            this.getData()
          }
        })
      }
    },
    editClue(i) {
      const { tagList, bindFlag, indoorFlag, invertFlag, ...d } = i
      d.personFlag = tagList.indexOf('101') > -1
      d.leaveTag = tagList.filter((item) => item != '101')[0]
      d.bindFlag = bindFlag == 1
      d.indoorFlag = indoorFlag == 1
      d.invertFlag = invertFlag == 1
      this.resetClue = JSON.parse(JSON.stringify(d))
      this.clueInfo = d
      this.clueType = 'edit'
      this.clueShow = true
    },
    resetClueFun() {
      this.clueInfo = JSON.parse(JSON.stringify(this.resetClue))
    },
    getMemberTagList() {
      this.$axios.post(this.baseURLApp + '/userExpansionBing/other/listBySource', { activityId: this.data.activityId }).then((res) => {
        this.memberTagList = res.data
      })
    },
    closeTagShow() {
      this.editTagShow = false
      this.tagName = ''
    },
    saveEditTag() {
      if (this.tagName.length < 1) {
        this.$toast('标签名不能为空')
        return
      }
      let url = '', d = {}
      if (this.editTagItem.name) {
        url = '/userExpansionBing/other/updateSource'
        d = { delFlag: false, sourceId: this.editTagItem.sourceId }
      } else {
        url = '/userExpansionBing/other/addSource'
      }
      this.$axios.post(this.baseURLApp + url, {
        activityId: this.data.activityId,
        userId: getParam().userId,
        name: this.tagName,
        ...d
      }).then((res) => {
        this.editTagShow = false
        this.getMemberTagList()
        this.tagName = ''
        this.editTagItem = {}
      })
    },
    editTagName(i) {
      this.tagName = i.name
      this.editTagItem = { ...i }
      this.editTagShow = true
    },
    delTag(i) {
      this.$axios.post(this.baseURLApp + '/userExpansionBing/other/updateSource', {
        activityId: this.data.activityId,
        userId: getParam().userId,
        name: i.name,
        delFlag: true,
        sourceId: i.sourceId
      }).then((res) => {
        this.getMemberTagList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";

.cline1 {
  padding: 16px;
  font-size: 22px;
}

.red {
  font-size: 20px;
  color: #F03C18;
}

.ctips {
  font-size: 22px;
  padding: 16px;
  color: #F03C18;
}

.member-box {
  .menber-item {
    padding: 28px 24px;
    font-size: 22px;
    color: #242831;
    border-bottom: 1px solid #EEEEEE;

    .avt {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 20px;
    }

    .line1 {
      font-size: 26px;
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .name {
        max-width: 180px;
        margin-right: 10px;
        font-weight: 600;
      }

      .bind {
        display: inline-block;
        border: 1px solid #9AA1A9;
        border-radius: 4px;
        font-size: 20px;
        padding: 2px 12px;
        margin-right: 10px;
      }

      .detail {
        color: #267DFF;
        flex: 1;
        text-align: right;
        font-size: 24px;

        i {
          margin-left: 3px;
        }
      }
    }

    .line2 {
      display: flex;
      align-items: center;

      .status {
        color: #20C07D;
        flex: 1;
        text-align: right;

        >span {
          >i {
            margin-right: 5px;
          }
        }

        .c2 {
          color: #242831;
        }

        .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #DDDDDD;
          transform: translateY(1px);
        }
      }
    }

    .bind {
      margin-top: 4px;
      display: flex;
      align-items: center;

      .do {
        padding: 2px 6px 2px 10px;
        border: 1px solid #ddd;
        color: #242831;
        margin-right: 8px;
        border-radius: 4px;
        font-size: 20px;
      }
    }

    .tag {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;

      >span {
        padding: 2px 12px;
        color: #267DFF;
        background-color: rgba(38, 125, 255, 0.06);
        border-radius: 4px;
        margin-right: 12px;
        margin-bottom: 12px;

      }

      .old {
        color: #20C07D;
        background-color: rgba(32, 192, 125, 0.06);
      }

      .new {
        color: #FF6E00;
        background-color: rgba(255, 110, 0, 0.06)
      }
    }

    .gift {
      font-weight: 400;
      margin-top: 2px;
      margin-bottom: 8px;
    }

    .line3,
    .line4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #3C454E;
    }
  }
}

.detail-box {
  .title {
    border-bottom: none;
  }

  .d-box {
    padding: 40px;

    .border {
      padding-bottom: 60px;
      border-bottom: 1px solid #EEEEEE;
    }

    >div {
      >p {
        font-size: 28px;
        font-weight: 600;
        display: flex;
        align-items: center;

        &::before {
          content: '';
          display: inline-block;
          width: 6px;
          height: 22px;
          background-color: #267DFF;
          margin-right: 8px;
          border-radius: 10px;
        }
      }

      >div>div {
        margin-top: 30px;

        >p:first-child {
          font-size: 24px;
          color: #666666;
          margin-bottom: 15px;
        }

        >p:nth-child(2) {
          font-size: 32px;
          color: #242831;
          font-family: BebasNeueBold;

          >span {
            font-size: 48px;
          }
        }

        >p:last-child {
          font-size: 24px;
          margin-top: 10px;
        }
      }
    }
  }
}

// 绑定失败查询
.fail-box {
  height: 900px;
  overflow: scroll;

  .fail-item {
    padding: 28px 24px;
    display: flex;
    font-size: 26px;

    .avt {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 20px;
    }

    p+p {
      margin-top: 10px;
    }
  }
}

.pop {
  .tag-box {
    // height: auto;
    height: 1000px;
    overflow: scroll;
  }

  .c-t>span {
    color: #F03C18;
  }

  .input {
    background-color: #F5F5F5;
    border: none;
    border-radius: 8px;
  }

  /deep/.input input::placeholder {
    font-size: 24px;
    font-weight: 400;
    color: #9AA1A9;
  }

  .nick {
    color: #6C727A;
    margin-top: 20px;
  }

  &.chose-t {
    .title {
      border-bottom: none;
    }

    .tips {
      padding: 14px 32px;
      font-size: 24px;

      >span {
        color: #267DFF;
        font-size: 29px;
        font-weight: 500;
      }
    }
  }
}


.change-pop {
  text-align: center;

  .title {
    border-bottom: none;
    background-color: #F9FAFB;
    font-weight: 400;
    font-size: 26px;
    height: 90px;
    line-height: 90px;
  }

  >div {
    height: 112px;
    line-height: 112px;
    font-size: 32px;
  }

  .gray {
    background-color: #F9FAFB;
    height: 16px;
  }
}

.tips-pop {
  text-align: center;
  width: 550px;
  font-size: 24px;
  border-radius: 12px;
  padding: 0px 40px;
  box-sizing: border-box;

  .title {
    padding-top: 54px;
    font-size: 36px;
    font-weight: 600;
  }

  p+p {
    margin-top: 28px;

    >span {
      color: #267DFF;
    }
  }

  .btns {
    border-top: 1px solid #EAEAEA;
    height: 112px;
    line-height: 112px;
    display: flex;
    font-size: 32px;
    color: #9AA1A9;
    margin-top: 48px;

    >span {
      flex: 1;

      &:last-child {
        color: #267DFF;
        border-left: 1px solid #EAEAEA;
        ;
      }
    }
  }
}

.member-tag-pop {
  background-color: #F5F5F5;

  .title {
    border-color: transparent;
  }

  .m-box {
    padding: 20px 32px;
    max-height: 700px;
    overflow: scroll;
    background-color: #F5F5F5;

    p {
      display: flex;
      align-items: center;
      font-size: 30px;
      padding: 36px 32px;
      background-color: #fff;
      border-radius: 12px;

      i {
        font-size: 40px;
      }

      &+p {
        margin-top: 16px;
      }
    }
  }

  .btn-box {
    background-color: #fff;

    .confirm {
      width: 100%;
    }
  }
}

.edit-tag-pop {
  .e-title {
    padding: 40px 32px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
      font-size: 42px;
      width: 32px;
    }

    .save {
      font-size: 26px;
      color: #9AA1A9;
      font-weight: 600;
      padding: 18px 28px;
      border-radius: 40px;
      background-color: #EEEEEE;
      box-sizing: border-box;

      &.can {
        background-color: #267DFF;
        color: #fff;
      }
    }
  }
}
</style>
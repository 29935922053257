<template>
  <div>
    <div class="chose-item">
      <p class="c-t">获客渠道</p>
      <div class="c-items">
        <span v-for="(i, key) in config.channel" v-if='hasAll(key)' :class="tagInfo.channelTag == key ? 'act' : ''"
          @click="tageFun('channelTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">意向产品</p>
      <div class="c-items">
        <span v-for="(i, key) in config.product" v-if='hasAll(key)' :class="tagInfo.productTag == key ? 'act' : ''"
          @click="tageFun('productTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">意向等级（到店体验时间）</p>
      <div class="c-items">
        <span v-for="(i, key) in config.level" v-if='hasAll(key)' :class="tagInfo.levelTag == key ? 'act' : ''"
          @click="tageFun('levelTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">体测情况</p>
      <div class="c-items">
        <span v-for="(i, key) in config.bodyTest" v-if='hasAll(key)' :class="tagInfo.bodyTestTag == key ? 'act' : ''"
          @click="tageFun('bodyTestTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">年龄</p>
      <div class="c-items">
        <span v-for="(i, key) in config.age" v-if='hasAll(key)' :class="tagInfo.ageTag == key ? 'act' : ''"
          @click="tageFun('ageTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">职业</p>
      <div class="c-items">
        <span v-for="(i, key) in config.job" v-if='hasAll(key)' :class="tagInfo.jobTag == key ? 'act' : ''"
          @click="tageFun('jobTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">健身需求</p>
      <div class="c-items">
        <span v-for="(i, key) in config.fitnessNeed" v-if='hasAll(key)'
          :class="tagInfo.fitnessNeedTag == key ? 'act' : ''" @click="tageFun('fitnessNeedTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">在馆体验时长</p>
      <div class="c-items">
        <span v-for="(i, key) in config.duration" v-if='hasAll(key)' :class="tagInfo.durationTag == key ? 'act' : ''"
          @click="tageFun('durationTag', key)">{{ i }}</span>
      </div>
    </div>
    <div class="chose-item">
      <p class="c-t">进馆体验</p>
      <div class="c-items">
        <span v-for="(i, key) in config.experience" v-if='hasAll(key)' :class="tagInfo.experienceTag == key ? 'act' : ''"
          @click="tageFun('experienceTag', key)">{{ i }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from './config.js'
export default {
  data() {
    return {
      config: {},
      tagInfo: {},
      tagList: [],
      tagObj: {}
    }
  },
  props: ['choseTag', 'type'],
  mounted() {
    this.config = config
    this.reset()
  },
  watch: {
    choseTag: {
      handler(v) {
        if (this.type != 'chose') {
          if (v.length) {
            this.getTagObj()
            Object.keys(this.tagObj).map(i => {
              v.map(i2 => {
                if (this.tagObj[i].indexOf(i2) > -1) {
                  this.$set(this.tagInfo, i, i2)
                }
              })
            })
          } else {
            this.tagInfo = {}
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    tageFun(tagName, key) {
      this.$set(this.tagInfo, tagName, key)
    },
    getTagList() {
      this.tagList = Object.values(this.tagInfo)
      this.tagList = this.tagList.filter(i => i % 10)
      return this.tagList
    },
    getTagObj() {
      let tagObj = {}
      Object.keys(config).map(i => {
        tagObj[i + 'Tag'] = Object.keys(config[i])
      })
      this.tagObj = tagObj
    },
    hasAll(key) {
      if (this.type != 'chose') {
        return key % 10
      }
      return true
    },
    reset() {
      console.log(this.type)
      if (this.type == 'chose') {
        Object.keys(config).map(i => {
          this.$set(this.tagInfo, i + 'Tag', Object.keys(config[i])[0])
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
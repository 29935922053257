<template>
  <van-calendar v-model="show" type="range" @confirm="choseTime" :min-date="minDate" :max-date="maxDate" color="#1989fa" :allow-same-day="true" :default-date="defaultDate" safe-area-inset-bottom>
    <template #title>
      <div class="title-box">
        <span class="all" @click="choseAll">全部</span><span>日期选择</span><span></span>
      </div>
    </template>
  </van-calendar>
</template>
<script>
import { getFormatDate } from '@/lib/utils'
export default {
  data() {
    return {
      show: false,
      defaultDate: [],
    }
  },
  props: ['minDate', 'maxDate'],
  methods: {
    showTime(d) {
      if (d.chose == '全部') {
        this.defaultDate = [this.minDate, this.maxDate]
      }else{
        let t = d.chose.split('-')
        this.defaultDate = [new Date(t[0].replace(/\./ig, '/')), new Date(t[1].replace(/\./ig, '/'))]
      }
      this.show = true
    },
    choseTime(date) {
      this.$emit('choseTime', { timeText: getFormatDate(date[0], 'yyyy.MM.dd') + '-' + getFormatDate(date[1], 'yyyy.MM.dd') })
      this.show = false
    },
    choseAll() {
      this.show = false
      this.$emit('choseAll')
    }
  }
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;

  >span {
    width: 33.33%;

    &:first-child {
      text-align: left;
      padding-left: 20px;
      font-weight: 400;
      color: #1989fa;
    }
  }
}
</style>
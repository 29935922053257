<template>
  <div>
    <van-popup v-model="show" round position="bottom" closeable safe-area-inset-bottom class="pop" @close="closeFun">
      <p class="title">筛选会员</p>
      <div class="chose-box">
        <div class="chose-item">
          <p class="c-t">手机号</p>
          <div class="c-items">
            <van-field class="input" v-model="unchoseM.mobile" maxlength="11" placeholder="在此输入用户手机号" />
          </div>
        </div>
        <!-- <div class="chose-item" v-if="menuIndex != 0 && memberList.length && memberList[0].userId && active != 7">
          <p class="c-t">团队成员</p>
          <div class="c-items">
            <span class="ellipsis-1" :class="unchoseM.userId == i.userId ? 'act' : ''"
              v-for="i in [{ userName: '全部', userId: '' }, ...teamList]" :key="i.userId"
              @click="changeChose('userId', i.userId)">{{ i.userName }}</span>
          </div>
        </div> -->
        <div class="chose-item" v-if="menuIndex != 0">
          <p class="c-t">{{ active == 7 ? '前绑定专员' : '团队成员' }}</p>
          <div class="c-items">
            <span class="ellipsis-1" v-for="(i, key) in [{ userName: '全部', userId: '' }, ...laveMemberList]"
              :class="unchoseM.lastShareUserId == i.userId ? 'act' : ''"
              @click="changeChose('lastShareUserId', i.userId)">{{ i.userName }}</span>
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">绑定状态</p>
          <div class="c-items">
            <span v-for="(i, key) in bindList" :class="unchoseM.bindFlag == key ? 'act' : ''"
              @click="changeChose('bindFlag', key)">{{ i }}</span>
          </div>
        </div>
        <tagVue ref="tagRef" :choseTag="unchoseM.tagList" type="chose" />
        <div class="chose-item">
          <p class="c-t">进馆情况</p>
          <div class="c-items">
            <span v-for="(i, key) in indoorList" :class="unchoseM.indoorFlag == key ? 'act' : ''"
              @click="changeChose('indoorFlag', key)">{{ i }}</span>
          </div>
        </div>
        <div class="chose-item">
          <p class="c-t">转化情况</p>
          <div class="c-items">
            <span v-for="(i, key) in invertList" :class="unchoseM.invertFlag == key ? 'act' : ''"
              @click="changeChose('invertFlag', key)">{{ i }}</span>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <span @click="reset">重置</span>
        <span class="confirm" @click="choseMemberConfirm">确认</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import tagVue from './tag.vue'
import Regular from '@/lib/regular'
export default {
  components: { tagVue },
  data() {
    return {
      show: false,
      bindList: ['全部', '已绑定','可转绑'],
      indoorList: ['全部', '已进馆', '未进馆'],
      invertList: ['全部', '已转化', '未转化'],
      timeType: '1',
      unchoseM: {
        mobile: '',
        bindFlag: '',
        indoorFlag: '',
        invertFlag: '',
        userId: '',
        tagList: [],
        lastShareUserId: ''
      },
      choseM: {},
      laveMemberList: []
    }
  },
  props: ['menuIndex', 'teamList', 'active', 'teamMemberList'],
  watch: {
    teamMemberList(v) {
      this.laveMemberList = this.teamFun(v)
    }
  },
  mounted() {
    this.choseM = JSON.parse(JSON.stringify(this.unchoseM))
  },
  methods: {
    teamFun(v) {
      let list = []
      if (v[0].userId) {
        list = v
        return list
      }
      v.map(i => {
        list = [...list, ...i.memberList]
      })
      list = list.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.userId === item.userId
        ))
      );
      return list
    },
    showFun() {
      this.show = true
    },
    closeFun() {
      this.show = false
      this.unchoseM = JSON.parse(JSON.stringify(this.choseM))
    },
    changeChose(tagName, key) {
      this.$set(this.unchoseM, tagName, key)
    },
    reset() {
      this.unchoseM = { indoorFlag: '', invertFlag: '', bindFlag: '', userId: '', mobile: '', tagList: [], lastShareUserId: '' }
      this.$refs.tagRef && this.$refs.tagRef.reset()
    },
    choseMemberConfirm() {
      this.choseM = JSON.parse(JSON.stringify(this.unchoseM))
      const { indoorFlag, invertFlag, bindFlag, userId, mobile, lastShareUserId } = this.choseM
      if (mobile && !Regular.isTelenum(mobile)) {
        this.$toast('请输入正确的手机号')
        return
      }
      let l = ['', 1, 0], tagList = this.$refs.tagRef.getTagList()
      let d = {
        indoorFlag: l[indoorFlag], invertFlag: l[invertFlag], bindFlag: l[bindFlag], mobile, tagList, lastShareUserId
      }
      this.menuIndex != 0 ? d.userId = userId : ''
      this.$emit('choseMemberConfirm', d)
      this.show = false
    },
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";

.input {
  background-color: #F5F5F5;
  border: none;
  border-radius: 8px;
}
</style>
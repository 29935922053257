<template>
  <div class="container">
    <!-- <nav-bar headerTitle="地推" :showBack="true" :header-back="appBack"></nav-bar> -->
    <van-sticky>
      <div class="top">
        <div class="row-between-center f44 fw6">
          <div class="row-start-start">
            <img v-if="info.user" :src="info.user.photo" alt="" class="avator">
            <p class="name f44 fw6">{{ info.user ? info.user.name : '团队业绩' }}</p>
          </div>
          <div @click="changeSight" v-if="isAdmin" class="check-button row-center-center">
            <van-icon size="4vw"
              name="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/cl_crm/img/achievements/exchange.png" />
            <span class="f24">{{ params.isLookAll ? '我的业绩' : '团队业绩' }}</span>
          </div>
        </div>
        <p class="f20 mt8">数据更新于:{{ info.updateTime }}</p>
      </div>
    </van-sticky>
    <div class="card-list">
      <div @click="$router.push(`/trader-push/data?isLookAll=${params.isLookAll}&activityId=${item.activityId}`)" class="card" v-for="item in info.activityStatistics" :key="item.activityId">
        <div class="row-between-center">
          <p class="p1 f32 fw6">{{ item.activityName }}</p>
          <van-icon name="arrow" color="#3875c6" />
        </div>
        <p class="f24 grey" style="margin: 4px 0 0;">{{ `${item.startTime} - ${item.endTime}` }}</p>
        <div class="num-box row-around-center">
          <div>
            <p class="blue f80 BebasNeueBold fw6">{{ item.newUserRegisterCount }}<span class="f20">人</span> </p>
            <p class="f24 grey">注册</p>
          </div>
          <div>
            <p class="blue f80 BebasNeueBold fw6">{{ item.buyCount }}<span class="f20">人</span> </p>
            <p class="f24 grey">购买</p>
          </div>
          <div>
            <p class="blue f80 BebasNeueBold fw6">{{ item.inDoorCount }}<span class="f20">人</span> </p>
            <p class="f24 grey">进馆</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navBar from '@/components/nav-bar/nav-bar'
import axios from 'axios'

export default {
  components: {
    navBar
  },
  data() {
    return {
      params: {
        activityId: '',
        isLookAll: false,
        sysUserId: ''
      },
      isAdmin: false,
      info: {},
    }
  },
  created() {
    const token = localStorage.getItem('traderpush-token')
    if (token) axios.defaults.headers['token'] = token

    const a = localStorage.getItem('traderpush-isLookAll')
    this.isAdmin = a ? JSON.parse(a) : false

    this.getList()
  },
  methods: {
    getList() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner",
      });
      this.$axios
        .post(this.baseURLApp + "/promoterData/activityOverview", this.params)
        .then((res) => {
          this.$toast.clear()
          this.info = res.data
        }).catch((r) => {
          this.$router.replace('/trader-push/login')
        })


    },
    changeSight() {
      this.params.isLookAll = !this.params.isLookAll
      this.getList()
    }
  },
}

</script>

<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }
}

.top {
  background-color: rgb(56, 117, 198);
  padding: 32px 24px;
  color: white;
  width: 100vw;

  .avator {
    width: 80px;
    margin: 0 24px 0 0;
  }

  .check-button {
    padding: 4px 12px;
    border: 1px solid #fff;
    border-radius: 4px;
  }
}

.card-list {
  padding: 32px;

  .card {
    background-color: #F6FAFF;
    border-radius: 8px;
    padding: 32px;
    margin: 0 0 24px;

    .p1 {
      span {
        color: #6C727A;
      }
    }

    .num-box {
      margin: 32px 0 0;
    }
  }

}
.grey {
  color: #6C727A;
}
.blue {
  color: #3875c6;
}
</style>

<template>
  <div class="container">
    <nav-bar @click-left="back" :title="info.user ? info.user.name : '团队数据'" background="rgb(56, 117, 198)"
      color="#fff"></nav-bar>
    <van-sticky>
      <div class="top">
        <div class="row-between-center">
          <div class="">
            <p class="name f36 fw6">{{ info.activityName }}</p>
            <p class="name f28">{{ `${info.startTime}-${info.endTime}` }}</p>
            <p class="name f22" style="margin: 8px 0 0;">数据更新于：{{ info.updateTime }}</p>
          </div>
          <div v-show="!params.sysUserId" @click="showPicker = true" class="check-button row-center-center">
            <van-icon name="tosend" size="4vw" />
            <span class="f22">{{ params.date || '全部时间' }}</span>
          </div>
        </div>
        <div class="num-box row-around-center">
          <div>
            <p class="f56 fw6">{{ info.newUserRegisterCount }} <span class="f16">人</span> </p>
            <p class="f24">注册</p>
          </div>
          <div>
            <p class="f56 fw6">{{ info.buyCount }} <span class="f16">人</span> </p>
            <p class="f24">购买</p>
          </div>
          <div>
            <p class="f56 fw6">{{ info.inDoorCount }} <span class="f16">人</span> </p>
            <p class="f24">进馆</p>
          </div>
        </div>
        <div class="desc-box">
          <div v-for="item in info.giftDetails" :key="item.giftId">
            <p class="name">{{ item.giftName }}</p>
            <div style="margin-top: 5px;">
              <p><span class="f36 fw6">{{ item.buyCount }}</span></p>
            </div>
          </div>
        </div>
      </div>
    </van-sticky>


    <div class="card-list f22">
      <div class="nav row-between-center">
        <p class="p1">{{ params.isLookAll ? '销售人员' : '日新增' }}</p>
        <p class="p2">注册</p>
        <p class="p2">进馆</p>
        <p class="p3" v-for="item in info.giftDetails" :key="item.giftId">{{ item.giftName }}</p>
      </div>
      <div class="card f30 row-between-center" v-for="item in info.activityDetailDayDatas" :key="item.activityId">
        <p v-if="item.sysUserId" class="p1 blue" style="font-size: 10px;"  @click="lookPerson(item)">{{ item.sysUserName }}</p>
        <p v-if="item.date" class="p1 f20">{{ item.date }}</p>
        <p class="p2">{{ `${item.newUserRegisterCount}` }}</p>
        <p class="p2">{{ `${item.inDoorCount}` }}</p>
        <p class="p3" v-for="ii in item.giftDetails" :key="ii.giftId">{{ `${ii.buyCount}` }}</p>
      </div>
    </div>

    <van-popup v-model="showPicker" position="bottom" z-index="2100" round>
     <div class="date-title">
        <span style="color: #1989fa;" @click="onDateCancel">重置</span>
        <span>日历</span>
        <span></span>
      </div>
       <van-calendar type="range" @confirm="onDateChange" :min-date="new Date('2017/01/01')" color="#1989fa"
        :allow-same-day="true" :poppable="false" :style="{ height: '500px' }" :show-title="false" ref="dateC" />
    </van-popup>

    <!-- <van-popup v-model="showPicker" position="bottom" z-index="2100">
      <van-datetime-picker v-model="currentDate" type="date" cancel-button-text="重置" @confirm="onDateChange"
        @cancel="onDateCancel" />
    </van-popup> -->
  </div>
</template>

<script>
import navBar from '@/components/app/headBar'
import axios from 'axios'
import { getParam, getFormatDate } from '../../lib/utils'

export default {
  components: {
    navBar
  },
  data() {
    return {
      currentDate: new Date(),
      params: {
        activityId: getParam().activityId,
        date: '',
        isLookAll: JSON.parse(getParam().isLookAll),
        sysUserId: ''
      },
      isAdmin: JSON.parse(getParam().isLookAll),

      info: {},
      showPicker: false,
    }
  },
  created() {
    const token = localStorage.getItem('traderpush-token')
    if (token) axios.defaults.headers['token'] = token

    this.getData()
  },
  methods: {
    getData() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner",
      });
      let d = JSON.parse(JSON.stringify(this.params))
      delete d.date
      this.$axios
        .post(this.baseURLApp + "/promoterData/activityDetail", d)
        .then((res) => {
          this.$toast.clear()
          this.info = res.data
        }).catch((r) => {
          this.$router.replace('/trader-push/login')
        })
    },

    onDateChange(v) {
      this.params.date = `${getFormatDate(v[0], 'yyyy-MM-dd')}~${getFormatDate(v[1], 'yyyy-MM-dd')}`
      this.params.start = getFormatDate(v[0], 'yyyy-MM-dd')
      this.params.end = getFormatDate(v[1], 'yyyy-MM-dd')
      // this.params.date = getFormatDate(v, 'yyyy-MM-dd')
      this.showPicker = false
      this.getData()
    },
    onDateCancel() {
      this.showPicker = false
      this.params.date = ''
      this.params.start = ''
      this.params.end = ''
      this.getData()
    },
    lookPerson(item) {
      this.params.sysUserId = item.sysUserId
      this.params.date = ''
      this.params.start = ''
      this.params.end = ''
      this.params.isLookAll = false
      this.getData()
    },
    back() {
      if (this.params.isLookAll || !this.isAdmin) {
        this.$router.go(-1)
      } else {
        this.params.sysUserId = ''
        this.params.isLookAll = true
        this.getData()
      }
    }
  },
}

</script>

<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }
}

.top {
  background-color: rgb(56, 117, 198);
  padding: 32px 24px;
  color: white;
  width: 100vw;

  .avator {
    width: 80px;
  }

  .check-button {
    padding: 8px 12px;
    border: 1px solid #fff;
    border-radius: 4px;

    span {
      margin: 0 0 0 4px;
    }
  }

  .num-box {
    margin: 32px 0 0;
  }

  .desc-box {
    padding: 24px 12px;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 12px;
    margin: 32px 0 0;
    color: rgba(255, 255, 255, .8);
    flex-wrap: wrap;
    display: flex;

    // text-align: center;
    >div {
      width: 25%;
      flex-shrink: 0;
      margin-bottom: 15px;
    }

    .name {
      font-size: 24px;
    }

    span {
      color: #fff;
    }
  }
}

.card-list {
  padding: 32px 12px;
  text-align: center;

  .p1 {
    width: 17%;

    &.blue {
      color: #3875c6;
    }
  }

  .p2 {
    width: 17%;
  }

  .p3 {
    width: 22%;
  }

  .card {
    background-color: #F6FAFF;
    border-radius: 8px;
    padding: 16px 0;
    margin: 12px 0 0;


  }
}

.date-title {
  display: flex;
  padding: 20px;
  padding-top: 30px;
  font-size: 30px;
  justify-content: space-between;
}
</style>

// app启动屏中转页
<template>
  <div class="page">
    <nav-bar headerTitle="" ref="head" :showBack="true" :headerBack="newAppBack"></nav-bar>

    <img class="start-img" :src="startImg" alt="">

    <img @click="goCoach" class="bottom-btn" src="https://img.chaolu.com.cn/ACT/sskkllxnd.png" alt="">
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import navBar from '@/components/nav-bar/nav-bar'
import { newAppBack, gotoCoachDetail, appGetLocalVenue } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import axios from 'axios'


export default {
  data() {
    return {
      search: {
        token: '',
        userId: '',
        venueId: '',
      },
      startImg: ''
    };
  },

  mixins: [userMixin],
  components: {
    navBar,
  },
  async created() {
    await this.$getAllInfo(['userId'])
    const local = await appGetLocalVenue()

    this.countPoint('232', '323', '1701')
    this.search.token = this.token
    this.search.userId = this.userId
    this.search.venueId = local || 1
    // this.search.cityId = this.cityId

    this.getPoster()
  },
  methods: {
    newAppBack,

    getPoster() {
      axios({
        headers: {
          "version": 1,
          "Content-Type": "application/json;charset=utf-8",
        },
        method: 'post',
        url: this.baseURLApp + '/app/getSplashData',
        data: this.search
      }).then(res => {
        this.startImg = `http://img.chaolu.com.cn${res.data.data.list[0].iosBigImg}`
      })
    },

    goCoach() {
      const id = getParam().teacherId
      gotoCoachDetail(id)
    },


  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  position: relative;
  * {
    box-sizing: border-box;
  }

  .start-img {
    width: 100%;
  }

  .bottom-btn {
    width: 156px;
    position: fixed;
    bottom: 160px;
    right: 40px;
    z-index: 1000;

  }

}</style>

<template>
  <div class="springAwakening2023_main">

    <nav-bar header-title="教练商城" backColor="#fff" :show-back="true" :header-back="newAppBack" ref="head" />
    <div class="headbg">
      <img src="https://img.chaolu.com.cn/ACT/coach-mall/banner.png" class="head-img" />
      <!-- <div class="rs">

        <div class="sec2" @click="showActivitiesRule = true">规则</div>
      </div> -->

    </div>
    <van-sticky :offset-top="stickyHeight">
      <div class="tofu">

        <div class="row-around-center">
          <div v-if="!isEmpty(perCoursePackageMap)" class="tm row-center-center f30 fw6" :class="{ active: activeKey == 3 }" @click="activeKey = 3">
            私教课包
          </div>
          <div v-if="!isEmpty(scheduleCoursePackageMap)" class="tm row-center-center f30 fw6" :class="{ active: activeKey == 4 }" @click="activeKey = 4">
            团课课包
          </div>
          <div v-if="!isEmpty(microList)" class="tm row-center-center f30 fw6" :class="{ active: activeKey == 5 }" @click="activeKey = 5">
            小班课课包
          </div>
        </div>
      </div>
    </van-sticky>
    <div class="goodsbox col-start-center">


      <!-- 私教 -->
      <template v-if="activeKey == 3">
        <div class="classimg f40 fw6 row-center-center">
          私教特惠课包·新人专享
        </div>
        <div class="seerule" @click="showRule = true">
          <img src="https://img.chaolu.com.cn/ACT/coach-mall/seerule.png" />
        </div>

        <div class="basebg pdt" v-for="(item, key) in perCoursePackageMap">
          <div class="coner f34 fw6 row-start-center">{{ key }}元私教课包</div>
          <div class="goods-model" v-for="(citem, index) in item">
            <div class="pub_full">
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="pub_onefull">
                <div class="goods-name ellipsis">{{ citem.itemName }}</div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.discountRate">
                    <div class="scale">{{ citem.discountRate }}</div>
                  </div>
                  <div class="label-model" v-if="citem.save">
                    <div class="scale">{{ citem.save }}</div>
                  </div>
                  <div class="label-model" v-if="citem.describe">
                    <div class="scale">{{ citem.describe }}</div>
                  </div>
                </div>
                <div class="price-line">
                  <span class="dl">¥</span>
                  <span class="price">{{ citem.salePrice }}</span>
                  <span class="oldprice">¥{{ citem.originalPrice }}</span>
                </div>
              </div>
            </div>
            <div class="pub_avg line2">
              <div class="invalid-time"><template v-if="citem.expiryDateDescribe"> {{
                citem.expiryDateDescribe }}</template></div>
              <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 10)">{{
                buttonText(citem) }}</div>
            </div>
          </div>
        </div>
      </template>

      <!-- 团课 -->
      <template v-else-if="activeKey == 4">
        <div class="classimg f40 fw6 row-center-center">
          团课特惠课包·新人专享
        </div>
        <div class="seerule" @click="showRule = true">
          <img src="https://img.chaolu.com.cn/ACT/coach-mall/seerule.png" />
        </div>
        <div class="basebg pdt" v-for="(item, key) in scheduleCoursePackageMap" :key="key">
          <div class="coner f34 fw6 row-start-center">{{ key }}元团课课包</div>
          <div class="goods-model" v-for="(citem, index) in item" :key="index">
            <div class="pub_full">
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="pub_onefull">
                <div class="goods-name ellipsis">{{ citem.itemName }}</div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.discountRate">
                    <div class="scale">{{ citem.discountRate }}</div>
                  </div>
                  <div class="label-model" v-if="citem.save">
                    <div class="scale">{{ citem.save }}</div>
                  </div>
                  <div class="label-model" v-if="citem.describe">
                    <div class="scale">{{ citem.describe }}</div>
                  </div>
                </div>
                <div class="price-line">
                  <span class="dl">¥</span>
                  <span class="price">{{ citem.salePrice }}</span>
                  <span class="oldprice">¥{{ citem.originalPrice }}</span>
                </div>
              </div>
            </div>
            <div class="pub_avg line2">
              <div class="invalid-time"><template v-if="citem.expiryDateDescribe"> {{
                citem.expiryDateDescribe }}</template></div>
              <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 10)">{{
                buttonText(citem) }}</div>
            </div>
          </div>
        </div>
      </template>

      <!--小班课 -->
      <template v-else-if="activeKey == 5">
        <div class="classimg f40 fw6 row-center-center">
          小班课特惠课包·新人专享
        </div>
        <div class="seerule" @click="showRule = true">
          <img src="https://img.chaolu.com.cn/ACT/coach-mall/seerule.png" />
        </div>
        <div class="basebg pdt" v-for="(item, key) in microList" :key="key">
          <div class="coner f34 fw6 row-start-center">{{ key }}元小班课课包</div>
          <div class="goods-model" v-for="(citem, index) in item" :key="index">
            <div class="pub_full">
              <div class="goods-img"><img :src="citem.picUrl" /></div>
              <div class="pub_onefull">
                <div class="goods-name ellipsis">{{ citem.itemName }}</div>
                <div class="label-line">
                  <div class="label-model" v-if="citem.discountRate">
                    <div class="scale">{{ citem.discountRate }}</div>
                  </div>
                  <div class="label-model" v-if="citem.save">
                    <div class="scale">{{ citem.save }}</div>
                  </div>
                  <div class="label-model" v-if="citem.describe">
                    <div class="scale">{{ citem.describe }}</div>
                  </div>
                </div>
                <div class="price-line">
                  <span class="dl">¥</span>
                  <span class="price">{{ citem.salePrice }}</span>
                  <span class="oldprice">¥{{ citem.originalPrice }}</span>
                </div>
              </div>
            </div>
            <div class="pub_avg line2">
              <div class="invalid-time"><template v-if="citem.expiryDateDescribe"> {{
                citem.expiryDateDescribe }}</template></div>
              <div class="buy" :class="{ gray: citem.itemStatus != 'CAN_BUY' }" @click="buyCard(citem, 10)">{{
                buttonText(citem) }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        {{ rules[activeKey].title }}
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showRule = false" />
      </div>
      <div class="pop-rule-content">
        <div v-for="item in rules[activeKey].content">{{ item }}</div>
      </div>
    </van-popup>
    <van-popup v-model="showActivitiesRule" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        活动规则
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="showActivitiesRule = false" />
      </div>

      <div class="pop-rule-content">
        <div>
          <p>1、活动时间：3月1日10:00-3月4日23:59</p>
          <p>2、春醒活动商品，购买后不支持退款。</p>
          <p>3、春醒活动商品，均设置有效期，请在有效期内尽快使用，逾期失效。</p>
          <p>4、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。</p>
        </div>
        <div>
          <p>—— 购买春醒任意活动商品，即可参与抽奖————</p>
          <p>1、抽奖时间截止至3月5日 10:00，逾期将视为自动放弃抽奖机会。</p>
          <p>2、抽中的奖品将以兑换券的形式放入您的账户中；抽中“IPhone14”，工作人员将在7个工作日内联系您。</p>
        </div>
        <div>
          <p>—— 小班课课包规则 ——</p>
          <p>1、小班课课包券可转赠给好友；但一张券只允许转赠一次，不可二次流转。</p>
          <p>2、小班课课包券不可与礼品卡、其他优惠券同时使用。</p>
          <p>3、春醒活动商品，购买后不支持退款。</p>
        </div>
        <div>
          <p>—— 自助健身年卡规则 ——</p>
          <p>1、自助健身年卡，可用于全城超鹿门店自助健身。</p>
          <p>2、使用该卡，除高峰时段（19:00-21:00）的其他时间段，可享受免费自助健身；每30天赠送12小时高峰时段（19:00-21:00）免费自助健身时长，超出12小时部分以5折计算自助健身费用。
          </p>
          <p>3、自助健身卡不可与优惠券同时使用。</p>
          <p>4、春醒活动商品，购买后不支持退款。</p>
        </div>
        <div>
          <p>—— 通用礼品卡规则 ——</p>
          <p>1、通用礼品卡，可用于按时计费的自助，按次单节购买私教、团课以及小班课等产品（除课包形式的产品）。</p>
          <p>2、300元通用礼品卡，每个ID限购一份，购买后不可转赠；其他礼品卡不限购买次数，购买后未经使用前可转赠给好友，使用后不可转赠。</p>
          <p>3、礼品卡不可与优惠券同时使用。</p>
          <p>4、春醒活动商品，购买后不支持退款。</p>
        </div>

        <div>
          <p>—— 私教课包规则 ——</p>
          <p>1、私教课包券可转赠给好友；但一张券只允许转赠一次，不可二次流转。</p>
          <p>2、私教课包券不可与礼品卡、其他优惠券同时使用。</p>
          <p>3、春醒活动商品，购买后不支持退款。</p>
        </div>
        <div>
          <p>—— 团课课包规则 ——</p>
          <p>1、团课课包券可转赠给好友；但一张券只允许转赠一次，不可二次流转。</p>
          <p>2、团课课包券不可与礼品卡、其他优惠券同时使用。</p>
          <p>3、春醒活动商品，购买后不支持退款。</p>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showCity" position="bottom" safe-area-inset-bottom class="popcity">
      <div class="buy-title">
        选择购买城市
        <van-icon name="cross" class="buy-close" color="#9AA1A9" @click="showCity = false" />
      </div>
      <div class="city-list">
        <div v-for="(item, index) in cityList" :key="index" class="city-model"
          :class="{ 'city-active': cityIndex === index }" @click="cityIndex = index">
          {{ item.name }}
          <div class="checkbox"></div>
        </div>
        <div class="next" @click="showSure = cityIndex != undefined ? true : false">下一步</div>
      </div>
    </van-popup>
    <van-popup v-model="showSure" position="center" class="popsure">
      <div class="sure-title">提示</div>
      <div class="sure-content">您当前购买的自助健身卡，仅限<span v-if="cityIndex != undefined">{{ cityList[cityIndex].name
      }}</span>市所有门店使用，请确认后再付款！</div>
      <div class="sure-sub" @click="next">我知道了</div>
    </van-popup>
    <div style="display:none">
      <img alt
        src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/tinified/sijiao.png" />
      <img alt src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/tinified/tk.png" />
      <img alt src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/tinified/xbk.png" />
      <img alt src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/tinified/lpk.png" />
      <img alt src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/tinified/zzk.png" />
    </div>

  </div>
</template>
<script>
import { newAppBack, initBack, appPay, appPaySuccess } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'

import userMixin from '@/mixin/userMixin'
import wx from 'weixin-js-sdk'
import headerMixin from '@/mixin/headerMixin'
import { isEmpty } from '@/lib/utils'

const activityNo = 'teacherStore';
const webUrl = `${window.location.origin}/#/coach-mall`
export default {
  components: {
    navBar,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      showShare: false,

      showRule: false,
      showSure: false,
      showActivitiesRule: false,

      userDetail: {}, //分享的用户信息

      activeKey: 3,

      cityList: [
        { name: '福州市' },
        { name: '厦门市' },
        { name: '广州市' }
      ],
      showCity: false,
      cityIndex: undefined,
      cardList: [],//  礼品卡
      microList: [],//  小班课
      scheduleCoursePackageMap: {}, // 团课
      perCoursePackageMap: {},// 私教
      rules: {
        1: {
          title: '自助健身年卡规则',
          content: [
            '1、自助健身年卡，可用于全城超鹿门店自助健身。',
            '2、使用该卡，除高峰时段（19:00-21:00）的其他时间段，可享受免费自助健身；每30天赠送12小时高峰时段（19:00-21:00）免费自助健身时长，超出12小时部分以5折计算自助健身费用。',
            '3、自助健身卡不可与优惠券同时使用。',
            '4、春醒活动商品，购买后不支持退款。',
            '5、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。'
          ]
        },
        2: {
          title: '通用礼品卡规则',
          content: [
            '1、通用礼品卡，可用于按时计费的自助，按次单节购买私教、团课以及小班课等产品（除课包形式的产品）。',
            '2、300元通用礼品卡，每个ID限购一份，购买后不可转赠；其他礼品卡不限购买次数，购买后未经使用前可转赠给好友，使用后不可转赠。',
            '3、礼品卡不可与优惠券同时使用。',
            '4、春醒活动商品，购买后不支持退款。',
            '5、活动最终解释权归超鹿所有。如有疑问可联系客服：400-700-5678。'
          ]
        },
        3: {
          title: '私教课包规则',
          content: [
            '1. 【适用门店】：购买的课包券可用于所有超鹿运动门店私教课约课；',
            '2. 【支付说明】：购买课包仅限使用支付宝、微信等现金支付；不可使用余额、礼品卡、优惠券等方式支付或抵扣费用；',
            '3. 【课包券使用说明】：提交订单时，选择课包券抵扣课程金额；若实付金额高于课包金额，可使用现金方式补齐差价。',
            '4. 【使用说明】：课包购买后不可退款、不可延期，不可转赠；',
            '5. 如有疑问，可联系超鹿客服：400-700-5678'
          ]
        },
        4: {
          title: '团课课包规则',
          content: [
            '1. 【适用门店】：购买的课包券可用于所有超鹿运动门店团课约课；',
            '2. 【支付说明】：购买课包仅限使用支付宝、微信等现金支付；不可使用余额、礼品卡、优惠券等方式支付或抵扣费用；',
            '3. 【课包券使用说明】：提交订单时，选择课包券抵扣课程金额；若实付金额高于课包金额，可使用现金方式补齐差价。',
            '4. 【使用说明】：课包购买后不可退款、不可延期，不可转赠；',
            '5. 如有疑问，可联系超鹿客服：400-700-5678'
          ]
        },
        5: {
          title: '小班课规则',
          content: [
            '1. 【适用门店】：购买的课包券可用于所有超鹿运动门店小班课约课；',
            '2. 【支付说明】：购买课包仅限使用支付宝、微信等现金支付；不可使用余额、礼品卡、优惠券等方式支付或抵扣费用；',
            '3. 【课包券使用说明】：提交订单时，选择课包券抵扣课程金额；若实付金额高于课包金额，可使用现金方式补齐差价。',
            '4. 【使用说明】：课包购买后不可退款、不可延期，不可转赠；',
            '5. 如有疑问，可联系超鹿客服：400-700-5678'
          ]
        }
      },
      stickyHeight: 0,
    }
  },
  methods: {
    newAppBack,
    isEmpty,
    buttonText(item = {}) {
      const status = item.itemStatus;
      const limit = item.hasBuyLimit;
      const json = {
        'NOT_STARTED': '待开启',
        'EXPIRED': '已结束',
        'SOLD_OUT': '已售罄',
        'HAVE_BUY': '已购买',
        'CAN_BUY': '立即购买',
        'NOT_BUY': '不可购买'
      }
      let text = json[status] || '立即购买';
      if (status == 'CAN_BUY' && limit) {
        text = '限量抢';
      }
      return text;
    },

    next() {
      this.showSure = false;
      this.showCity = false;
      if (this.cityIndex !== undefined) {
        const payitem = this.zzkList[this.cityIndex];
        console.log(payitem)
        this.buyCard(payitem, 14);
      }
    },
    getActivityInfo() {
      this.$axios
        .post(this.baseURLApp + '/userDistribute/bindUser', {
          ...this.params,
          saleTeacherId: this.inviteTeacherId,
        })
        .then((res) => {


        });
    },
    getGoodList() {
      this.$axios.post(`${this.baseURLApp}/anniversary/sale/getItemList`, this.params).then(res => {
        const r = res.data;

        this.cardList = r.cardList  //  礼品卡
        this.microList = r.microCoursePackageMap  //  小班课

        this.scheduleCoursePackageMap = r.scheduleCoursePackageMap  // 团课
        this.perCoursePackageMap = r.perCoursePackageMap  // 私教
        if (this.cityId == '818447834079563776') {
          delete this.perCoursePackageMap[240]
        }
      })
    },
    async buyCard(item, type) {

      if (item.itemStatus !== 'CAN_BUY') {
        this.$toast('很遗憾，您暂不满足购买条件。')
        return
      }
      const res = await this.checkCanBuy(item);
      if (!res) {
        this.getGoodList()
        return
      }

      // 服务端需要 做个转换
      const obj = JSON.parse(JSON.stringify(item))
      obj.itemId = obj.isFlashSale ? obj.itemId.split('-')[0] : obj.itemId

      this.payAction(obj, type)
    },
    payAction(info, type) {
      let url = `/pages/webView/index?webUrl=${webUrl}&userId=1&token=1`
      let payData = {
        subject: info.itemName,
        extendsJson: {
          appVersion: '2.1.11',
          seriesName: '教练商城',
          // inviteUserId: this.inviteId,
        },
        totalAmount: info.salePrice,
        venueId: '31',
        goodsNum: type === 12 ? '1' : undefined,
        isApp: '1',
        type: type, // 礼品卡12  课包10
        userId: this.params.userId,
        token: this.params.token,
        extendsType: '4',
        mouldId: info.itemId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: info.itemName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      }
      appPay(payData, url);
      console.log(payData, url);
      if (this.appTypeStr !== 'mini') {
        appPaySuccess().then(() => {
          this.paySuccess();
          this.$toast('支付成功');
        })
      }
    },
    paySuccess() {
      console.log('suc')
      setTimeout(() => {
        this.getGoodList();
        this.getActivityInfo();
      }, 500)
    },
    checkCanBuy(info) {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/anniversary/sale/checkCanBuy', { ...this.params, itemId: info.itemId }).then((r) => {
        this.$toast.clear()
        return r.code
      }).catch(() => {
        this.$toast.clear()
        return 0
      })
    },
    openBuyCity() {
      this.cityIndex = undefined;
      this.showCity = true;
    },

  },
  mounted() {
    setTimeout(() => {
      this.stickyHeight = this.$refs.head.$el.offsetHeight
    }, 1000)
  },
  async created() {
    initBack();
    this.inviteTeacherId = this.$route.query.teacherId || this.$route.query.inviteTeacherId

    await this.$getAllInfo(['userId', 'cityId']);
    console.log(this.cityId, 'cccccc');
    this.params = {
      activityNo,
      userId: this.userId,
      token: this.token,
      hasWx: true
    }
    // this.initQr();
    this.getActivityInfo();

    this.getGoodList()

  },

}
</script>
<style lang="less" scoped>
.springAwakening2023_main {
  min-height: 100vh;
  background: #5AAAFF;

  div {
    box-sizing: border-box;
  }

  img {
    vertical-align: top;
    width: 100%;
  }

  .leftbg .van-icon-arrow-left {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    text-align: center;
    line-height: 64px;
    font-size: 38px !important;
  }

  .share {
    width: 46px;
    height: 46px;
  }

  .headbg {
    position: relative;
    background: #5AAAFF;
    margin: 0 0 -96px;
    .rs {
      position: absolute;
      right: 0;
      top: 176px;
      width: 88px;

      .sec1,
      .sec2 {
        line-height: 56px;
        background: rgba(36, 40, 49, 0.3);
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 24px;
        margin-bottom: 32px;
        padding-left: 6px;
      }
    }


  }

  .goods-title {
    height: 100px;
    margin-top: 40px;
  }

  .tofu {
    padding: 16px 32px;
    position: relative;
    background: #5AAAFF;

    .tm {
      width: 216px;
      height: 72px;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      color: #fff;

      &.active {
        color: #424990;
        background: #fff;
      }
    }


  }

  .goodsbox {
    padding: 0 32px 50px;
    margin-top: 40px;

    .classimg {
      height: 58px;
      width: fit-content;
      padding: 0 68px;
      background-image: url(https://img.chaolu.com.cn/ACT/coach-mall/title-bg-l.png), url(https://img.chaolu.com.cn/ACT/coach-mall/title-bg.png);
      background-size: 68px 58px;
      background-position: left top, right top;
      background-repeat: no-repeat;
      color: #fff;
    }

    .seerule {
      width: 160px;
      height: 42px;
      margin: 28px auto 44px;
    }

    .basebg {
      width: 100%;
      background: #fff;
      border-radius: 16px;
      position: relative;
      padding: 28px 24px 12px;
      margin-bottom: 42px;
      min-height: 300px;
    }

    .pdt {
      padding-top: 94px;
    }

    .coner {
      position: absolute;
      top: -10px;
      left: 0;
      width: 344px;
      height: 72px;
      background-image: url(https://img.chaolu.com.cn/ACT/coach-mall/s-title.png);
      background-size: 100% 100%;
      padding: 0 0 0 28px;
      color: #424990;
    }

    .goods-model {
      background: #EDF9FF;
      border-radius: 12px;
      margin-bottom: 24px;
      padding: 22px;
      height: 292px;

      .goods-img {
        width: 264px;
        height: 160px;
        margin-right: 22px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        img {
          object-fit: cover;
        }
      }

      .goods-name {
        font-weight: bold;
        color: #242831;
        font-size: 26px;
        padding-top: 2px;
      }

      .label-line {
        margin: 14px 0 14px;
        white-space: nowrap;

        .label-model {
          padding: 0 10px;
          height: 36px;
          background: linear-gradient(129deg, #FF8D4D 0%, #FF4E44 100%);
          border-radius: 6px;
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          margin-right: 12px;
          line-height: 1;
          display: inline-flex;
          align-items: center;

          .scale {
            transform: scale(0.9);
            transform-origin: 50% 50%;
          }
        }

        .limit {
          padding: 0;
          background: #79AF52;
        }
      }

      .price-line {
        .dl {
          font-size: 24px;
          color: #F03C18;
          font-weight: bold;
          margin-right: 4px;
        }

        .price {
          font-size: 48px;
          color: #F03C18;
          font-weight: bold;
          margin-right: 20px;
          position: relative;
          top: 2px;
        }

        .oldprice {
          text-decoration: line-through;
          color: #6C727A;
          font-size: 24px;
        }
      }

      .line2 {
        align-items: center;
        margin-top: 14px;

        .invalid-time {
          color: #3C454E;
          font-size: 24px;
          transform: scale(0.9);
          transform-origin: 0 50%;
        }

        .buy {
          width: 224px;
          height: 72px;
          background: linear-gradient(90deg, #FFA45C 0%, #FE3A74 100%);
          border-radius: 12px;
          color: #fff;
          font-weight: bold;
          text-align: center;
          line-height: 72px;
          font-size: 32px;
        }

        .gray {
          color: #6C727A;
          background: #DDDDDD;
          box-shadow: none
        }
      }
    }
  }

  .pop-rule {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .pop-rule-title {
      height: 132px;
      color: #424990;
      font-weight: bold;
      background: linear-gradient(180deg, #D1E9FF 0%, #FCFEFF 100%);
      font-size: 32px;
      text-align: center;
      line-height: 130px;
      position: relative;

      .pop-rule-close {
        position: absolute;
        right: 32px;
        top: 38px;
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        background: rgba(39, 40, 49, 0.2);
        font-size: 28px;
      }
    }

    .pop-rule-content {
      color: #242831;
      font-size: 26px;
      padding: 0 52px;
      max-height: 600px;
      overflow: auto;
      padding-bottom: 60px;

      div {
        margin-bottom: 46px;
        line-height: 1.6;
      }
    }
  }


  .popcity {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .buy-title {
      font-weight: bold;
      color: #242831;
      font-size: 32px;
      border-bottom: 1px solid #EEEEEE;
      height: 120px;
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buy-close {
      font-size: 36px;
    }

    .city-list {
      padding: 8px 32px 32px;

      .city-model {
        margin-top: 24px;
        height: 128px;
        background: #F5F5F5;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
        font-weight: bold;

        .checkbox {
          width: 32px;
          height: 32px;
          border: 1px solid #DDD;
          border-radius: 50%;
          margin-right: 8px;
        }
      }

      .city-active {
        border: 4px solid #242831;

        .checkbox {
          width: 48px;
          height: 48px;
          background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/check.png);
          background-size: 100% 100%;
          border: none;
          margin: 0;
        }
      }
    }

    .next {
      line-height: 96px;
      background: #FFDE00;
      border-radius: 8px;
      margin-top: 64px;
      font-weight: bold;
      text-align: center;
    }
  }

  .popsure {
    width: 590px;
    border-radius: 12px;
    padding: 50px 40px;

    .sure-title {
      color: #242831;
      font-size: 36px;
      font-weight: bold;
      text-align: center;
    }

    .sure-content {
      color: #3C454E;
      font-size: 24px;
      line-height: 1.6;
      margin: 36px 0 48px;
      text-align: center;

      span {
        color: #F03C18;
      }
    }

    .sure-sub {
      border-radius: 8px;
      background: #FFDE00;
      color: #000;
      font-weight: bold;
      font-size: 32px;
      line-height: 96px;
      text-align: center;
    }
  }

  @bei: 1.5px;

  .canvascss {
    position: fixed;
    bottom: -200%;
    // top: 0;
    width: @bei*654;
    height: @bei*1120;
    margin: 0 auto;
    box-sizing: border-box;

    .canvascss_bg {
      width: 100%;
      height: 100%;
    }



    .canvascss_left {
      position: absolute;
      left: @bei*32;
      bottom: @bei*36;
      z-index: 1;
      width: @bei*100;
      height: @bei*100;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .canvascss_name {
      position: absolute;
      left: @bei*162;
      bottom: @bei*112;
      z-index: 1;
      color: #242831;
      font-weight: bold;
      font-size: @bei*28;
      width: @bei*252;
    }

    .canvascss_right {
      position: absolute;
      right: @bei*34;
      bottom: @bei*24;
      width: @bei*140;
      height: @bei*140;
      border-radius: @bei*14;
      overflow: hidden;
      background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/spring2023/border.png);
      background-size: 100% 100%;
      padding: @bei*18;

      img {
        width: @bei*104;
        height: @bei*104;
      }
    }

  }

  /deep/.canvas-box {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;

    .create-img {
      width: 100% !important;
    }
  }
}</style>

<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="refreshFun" pulling-text="继续往下拉" loosing-text="该放手了，我要刷新啦..."
      :style="{ height: 'calc(100vh - ' + listHeight + 'px)', overflow: 'scroll' }" loading-text="拼命加载中"
      :pull-distance="100">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getData" :immediate-check="false">
        <div class="chose">
          <span class="f24" style="width: 70%;">以下仅为会员扫本{{ menuIndex == 0 ? '人本' : '团队成员'
          }}商城码后购买的商城商品明细。不限是否绑定。</span>
          <span class="chose-btn" @click="show = true">
            <span>筛选会员</span><van-icon name="arrow-down" />
          </span>
        </div>
        <div class="buy-box iphonex-pb">
          <div class="buy-detai" v-for="i in list">
            <img class="avt" :src="i.headImg" alt="">
            <div class="pub_onefull">
              <div class="buy">
                <div class="item1">
                  <span class="fw6 ellipsis">{{ i.userName }}</span>
                  <span class="f22">{{ i.mobile }}</span>
                </div>
                <div class="item2">
                  <span class="row-start-center">
                    <span style="color:#242831;max-width:110px;" class="ellipsis-1">{{ i.productName }}</span>
                    <span class="refund" v-if="i.isRefund == 1">已退款</span>
                  </span>
                  <span class="f22">{{ i.payTime }}</span>
                </div>
                <div class="item3">
                  <span class="blue">¥{{ i.payAmount }}</span>
                  <span class="pay1" v-if="i.finalPayStatus === '1'">已支付尾款</span>
                  <span class="pay2" v-if="i.finalPayStatus === '0'">未支付尾款</span>
                </div>
              </div>
              <p class="buy-user" v-if="menuIndex != 0">PP拓展专员：{{ i.saleUserName }}</p>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" round position="bottom" closeable class="pop" @close="closeMemeberFun"
      safe-area-inset-bottom>
      <p class="title">筛选会员</p>
      <div class="chose-box">
        <div class="chose-item">
          <p class="c-t">购买商品类型</p>
          <div class="c-items">
            <span v-for="(i, key) in typeList" :class="unBuyType == key ? 'act' : ''" @click="buyTypeFun(key)">{{ i
            }}</span>
          </div>
        </div>
      </div>
      <div class="btn-box">
        <span @click="rest">重置</span>
        <span class="confirm" @click="choseMemberConfirm">确认</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      refreshing: false,
      loading: false,
      finished: false,
      page: 1,
      list: [],
      emptyHeight: 0,
      // 搜索
      show: false,
      typeList: ['全部', '超鹿月卡', '包月私教卡', '私教课包', '礼品卡'],
      productTypeList: [[], [25], [24, 26], [16], [12]],
      buyType: 0,
      unBuyType: 0,
    }
  },
  props: ['menuIndex', 'listHeight'],
  methods: {
    getData(d) {
      d ? this.data = d : ''
      let d2 = { pageSize: 10, pageNum: this.page, productTypeList: [], ...this.data }
      this.$axios.post(this.baseURLApp + "/userExpansionBing/query/getOrderDetail", d2)
        .then((res) => {
          this.refreshing = false
          this.loading = false
          this.page++
          this.list = this.list.concat(res.data.records)
          if (res.data.records.length < d2.pageSize) {
            this.finished = true
          }
        })
    },
    refreshFun(type) {
      this.list = []
      this.page = 1
      this.finished = false
      this.loading = true
      this.getData()
      if (!type) {
        this.$emit('refresh')
      }
    },
    buyTypeFun(key) {
      this.unBuyType = key
    },
    closeMemeberFun() {
      this.show = false
      this.unBuyType = this.buyType
    },
    rest() {
      this.unBuyType = 0
    },
    choseMemberConfirm() {
      this.show = false
      this.buyType = this.unBuyType
      this.data.productTypeList = this.productTypeList[this.buyType]
      this.refreshFun(true)
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";

.empty {
  margin-top: 100px;
  text-align: center;
  color: #6C727A;
  font-size: 26px;
  font-weight: 400;

  >img {
    width: 240px;
    height: 240px;
    margin-bottom: 20px;
  }
}

.chose-box {
  height: auto !important;
}

.buy-box {
  .buy-detai {
    display: flex;
    align-items: center;
    padding: 28px 24px;

    .avt {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 20px;
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16);
    }

    .buy {
      display: flex;
      font-size: 24px;
      color: #6C727A;

      >div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        >span+span {
          margin-top: 10px;
        }
      }

      .item1 {
        width: 160px;
        margin-right: 15px;

        >span:first-child {
          color: #3C454E;
          width: 160px;
        }
      }

      .item2 {
        flex: 1;
        margin-right: 10px;

        >span {
          display: flex;
          align-items: center;
        }

        .refund {
          background-color: #FF6E00;
          color: #fff;
          font-size: 18px;
          padding: 2px 8px;
          border-radius: 4px;
          margin-left: 10px;
        }
      }

      .item3 {
        width: 120px;
        text-align: right;
        display: flex;
        justify-content: center;

        .pay1,
        .pay2 {
          font-size: 20px;
          font-weight: 500;
          color: #F03C18;
          // margin-top: 10px;
          background-color: rgba(240, 60, 24, 0.06);
          border-radius: 4px;
          padding: 2px 8px;
        }

        .pay1 {
          background-color: rgba(32, 192, 125, 0.06);
          color: #20C07D;
        }
      }
    }

    .buy-user {
      color: #6C727A;
      font-size: 22px;
      margin-top: 10px;
    }
  }
}
</style>
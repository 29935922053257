<template>
  <div class="container">
    <headBar ref="headerHeight" title="课包购买" left-arrow @click-left="newAppBack" :statusBar="!(appTypeStr == 'and')">
      <template slot="right">
        <div @click="showShareFun">
          <img class="share" src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/common/share-icon.png" />
        </div>
      </template>
    </headBar>
    <van-sticky :offset-top="headerHeight">
      <van-tabs v-if="tabList.length > 1" line-width="15" line-height="2" @change="tabChange"
        :title-active-color="tabAndImgObj[tabkey].color" :color="tabAndImgObj[tabkey].color" v-model="tabkey">
        <van-tab :title="item.subfieldName" :name="item.id" v-for="item in tabList" :key="item.id"></van-tab>
      </van-tabs>
    </van-sticky>
    <div class="block">
      <div class="col-between-start" v-if="tabList.length > 1">
        <img :src="tabAndImgObj[tabkey].img">
      </div>
      <div class="package-title">
        购买课包
      </div>
      <div class="select-box row-start-center flex-wrap" v-if="groupInfos.length > 0">
        <div v-for="item in groupInfos" :key="item.id" @click="switchSubfield(item)"
          :class="['select-item', 'row-center-center', 'f24', selectSubfield.id === item.id ? 'active' : '', 'c' + tabkey]">
          {{ item.groupMenuName }}</div>
      </div>
      <div class="package-box">
        <div v-for="group in selectSubfield.goodsInfos" class="pack-obj">
          <div v-for="item in group.itemInfos"
            :class="['package-item', 'row-between-center', selectItem.id === item.id ? 'active' : '', 'c' + tabkey]"
            :key="item.id" @click="switchItem(item, group)">
            <div class="package-item-left">
              <div>
                <p>{{ item.productName }}</p>
                <!-- <span class="tips">有效期：{{ item.extendInfo.validDate }}天</span> -->
                <span class="tips">{{ group.hint }}</span>
              </div>
              <div>
                <span>¥{{ item.extendInfo.discountSinglePrice }}/节</span>
                <span class="original-tips">¥{{ item.extendInfo.originalSinglePrice }}/节</span>
              </div>
            </div>
            <div class="package-item-right">
              <p class="f32 fw6">¥{{ item.salePrice }}</p>
              <p class="f22 p2">¥{{ item.originPrice }}</p>
            </div>
          </div>
        </div>
        <div class="rule-box">
          <p class="title f32 fw6">购买须知</p>
          <p class="p1 f24">
            {{ choseTabObj.saleRule }}
          </p>
        </div>
      </div>
    </div>
    <div class="bottom-btn" v-if="selectItem.salePrice">
      <div class="bottom-btn-shadow">
        <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/package_presentation3.png">
      </div>
      <div class="bottom-btn-box row-between-center">
        <div class="price row-start-center f42 fw6">￥{{ selectItem.salePrice || 0 }} <div
            class="discount row-center-center f20 fw6">可省{{ selectItem.extendInfo.savePrice }}元</div>
        </div>
        <div :class="['btn', 'c' + tabkey, 'f32', 'fw6', 'row-center-center', goodsStatus == 'CAN_BUY' ? '' : 'disabled']"
          @click="subOrder">立即购买</div>
      </div>
    </div>
    <!--  分享  -->
    <common-share :value="showShare" :shareParams="shareParams" :shareItem="['minifriend']" @close="showShare = false"
      @share-success="showShare = false">
    </common-share>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack, appPay, appPaySuccess, hideAppBackBtn } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import headBar from '@/components/app/headBar'
import commonShare from '@/components/commonShare'
import wx from 'weixin-js-sdk'
const shopId = '81'

export default {
  mixins: [userMixin],
  components: {
    headBar,
    commonShare
  },
  data() {
    return {
      showShare: false,
      shareParams: {
        title: '上超鹿，买课包；上精选团课、私教课和小班课；',
        miniImage: 'https://img.chaolu.com.cn/MINI/minicard/5.png',
        path: '',
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: 'url',
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },
      headerHeight: 0,
      tabList: [],
      tabkey: '',
      tabAndImgObj: {
        44: {
          color: '#818FFE',
          img: 'https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/package_presentation2.png'
        },
        43: {
          color: '#FDB928',
          img: ' https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/WeChat/package_presentation1.png'
        },
        45: {
          color: '#FF9747',
          img: 'https://img.chaolu.com.cn/ACT/xiaobanke.png'
        },
      },
      choseTabObj: {},
      groupInfos: {},
      selectSubfield: {}, // 选中的分组
      groupItem: {}, // 选中的课包父级
      selectItem: {}, // 选中的课包
      goodsStatus: '', // 购买按钮状态
    }
  },
  computed: {},
  async created() {
    await this.$getAllInfo(['userId', 'cityId'])
    initBack()
    hideAppBackBtn()
    this.getShop()
    if (this.appTypeStr === "mini") {
      const uu = encodeURIComponent(`${window.location.origin}/#/course-buy-bymall`)
      this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
      wx.miniProgram.postMessage({
        data: {
          type: "share",
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      });
      return false;
    }
  },
  mounted() {
    if (this.appTypeStr !== 'mini') {
      setTimeout(() => {
        this.headerHeight = this.$refs.headerHeight.$el.clientHeight || 0
      }, 1500)
    }
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        this.getShop()
      }
    })
  },
  methods: {
    newAppBack,
    showShareFun() {
      this.showShare = true
    },
    getShop() {
      this.$axios.post(this.baseURLApp + '/activityShopWeb/getShop', {
        cityId: this.cityId,
        shopId,
        userId: this.userId,
      }).then((res) => {
        this.tabList = res.data.partitions.subfieldsInfos.slice(2)
        this.tabList = this.tabList.slice(0, 3)
        let key = getParam().packageType ? getParam().packageType : this.tabList[0].id
        this.tabChange(key * 1)
      })
    },
    tabChange(key) {
      this.tabkey = key
      this.choseTabObj = this.tabList.find(item => item.id == key)
      this.groupInfos = this.choseTabObj.groupInfos
      this.switchSubfield(this.choseTabObj.groupInfos[0])

      // 修改分享参数
      const url = encodeURIComponent(`${window.location.origin}/#/course-buy-bymall`)
      this.shareParams.path = `/pages/webView/index?packageType=${this.tabkey}&webUrl=${url}`
      wx.miniProgram.postMessage({
        data: {
          type: 'share',
          shareImage: this.shareParams.miniImage,
          shareTitle: this.shareParams.title,
          shareUrl: this.shareParams.path,
        },
      })

    },
    switchSubfield(subfield) {
      if (this.selectSubfield.id === subfield.id) return
      this.selectSubfield = subfield
      this.switchItem(subfield.goodsInfos[0].itemInfos[0], subfield.goodsInfos[0])
    },
    switchItem(item, group) {
      if (this.selectItem.id === item.id) return
      this.selectItem = item
      this.groupItem = group
      this.getBagStatus()
    },
    getBagStatus() {
      const params = { goodsId: [this.groupItem.id], shopId, userId: this.userId, }
      this.$axios.post(`${this.baseURLApp}/activityShopWeb/getGoodsStatus`, params).then(res => {
        this.goodsStatus = res.data.goodsStatusMap[this.groupItem.id]
      }).catch(() => {
        this.goodsStatus = ''
      })
    },
    checkCanBuy() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: 'spinner',
      })
      return this.$axios.post(this.baseURLApp + '/activityShopOrder/checkBuy', {
        goodsId: this.groupItem.id,
        goodsItemId: this.selectItem.id,
        shopId,
        userId: this.userId,
      }).then((r) => {
        this.$toast.clear()
        return r.data
      }).catch(() => {
        this.$toast.clear()
        return 0
      })
    },
    async subOrder() {
      const res = await this.checkCanBuy()
      if (!res) {
        this.$toast('无法购买')
        return
      }
      this.payAction()
    },
    payAction() {
      let url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/coach-specialbag&userId=1`
      let payData = {
        subject: this.selectItem.productName,
        extendsJson: {
          appVersion: '2.1.11',
          shopId,
          activityShopGoodsId: this.groupItem.id + '',
          activityShopGoodsItemId: this.selectItem.id
        },
        totalAmount: this.selectItem.salePrice + '',
        venueId: '31',
        goodsNum: [12, 22].includes(this.groupItem.goodsType) ? '1' : undefined,
        isApp: '1',
        type: this.groupItem.goodsType, // 礼品卡12  课包10 实体卡22
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        mouldId: this.selectItem.productId,
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.selectItem.productName,
      }
      if (this.appTypeStr === 'mini') {
        payData.wxPayType = 'applet'
      } else {
        appPaySuccess().then(() => {
          this.$toast('支付成功')
        })
      }
      appPay(payData, url)
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  * {
    box-sizing: border-box;
  }

  .share {
    width: 50px;
  }

  .tabs {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .block {
    padding: 32px;
    min-height: 90vh;

    .package-title {
      font-size: 32px;
      font-weight: bold;
      color: #1f2025;
      padding: 30px 0px 20px;
    }

    .select-item {
      margin: 16px 28px 0 0;
      padding: 12px 28px;
      background: #F6F6F6;
      color: #3C454E;
      border-radius: 64px;
      font-size: 24px;
      width: 210px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;

      &.active {
        color: #fff;

        &.c44 {
          background-color: #818FFE;
        }

        &.c43 {
          background-color: #FDB928
        }

        &.c45 {
          background-color: #FF9747;
        }
      }

    }

    .select-box>.select-item:nth-child(3n) {
      margin-right: 0;
    }

    .package-box {
      margin: 40px 0 0;

      .pack-obj+.pack-obj {
        margin-top: 30px;
      }

      .package-item {
        padding: 40px 40px 40px 30px;
        border: 1px solid #E6E6E6;
        border-radius: 8px;

        &.active {
          &.c44 {
            border: 1px solid #818FFE;
            background: rgba(129, 143, 254, 0.1);
          }

          &.c43 {
            border: 1px solid #FDB928;
            background: rgba(253, 185, 40, 0.1);
          }

          &.c45 {
            border: 1px solid #FF9747;
            background: rgba(255, 151, 71, 0.1);
          }
        }
      }

      .package-item-left>div:first-child {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 32px;

        .tips {
          font-size: 24px;
          color: #6C727A;
          font-weight: normal;
          margin-top: 16px;
          display: inline-block;
        }
      }

      .package-item-left>div:first-child>text {
        color: #666666;
        font-size: 24px;
        font-weight: normal;
        margin-left: 20px;
      }

      .package-item-left>div:last-child {
        color: #F03C18;
        font-size: 24px;
        font-weight: bold;
        line-height: 24px;
        display: flex;
        align-items: flex-end;

        .original-tips {
          margin-left: 12px;
          line-height: 22px;
          font-size: 22px;
          color: #6C727A;
          font-weight: normal;
          text-decoration: line-through;
        }
      }

      .package-item-right {
        color: #F03C18;
        flex-shrink: 0;
        text-align: right;
      }

      .package-item-right .p2 {
        color: #6C727A;
        text-decoration: line-through;
      }
    }

    .rule-box {
      margin: 42px 0 0;

      .p1 {
        margin: 16px 0 0;
        line-height: 40px;
        white-space: pre-line;
      }
    }
  }

  .bottom-btn {
    width: 100vw;
    position: sticky;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    box-sizing: border-box;

    .bottom-btn-shadow {
      font-size: 0px;

      image {
        width: 100%;
        height: 12px;
        display: block;
      }
    }

    .bottom-btn-box {
      background-color: white;
      padding: 20px 30px calc(20px + env(safe-area-inset-bottom));

      .price {
        color: #F03C18;

        .discount {
          padding: 0 10px;
          height: 36px;
          background: #F03C18;
          border-radius: 4px;
          margin: 0 0 0 16px;
          color: #fff;
        }
      }

      .btn {
        color: white;
        width: 232px;
        height: 80px;
        border-radius: 40px;

        &.c44 {
          background-color: #818FFE;
        }

        &.c43 {
          background-color: #FDB928
        }

        &.c45 {
          background-color: #FF9747;
        }

        &.disabled {
          background-color: #DDDDDD;
          color: #9AA1A9;
        }
      }
    }
  }
}
</style>

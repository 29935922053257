<template>
  <div class="springAwakening2023_main col-center-center">

    <nav-bar header-title="超鹿会员商城" :show-back="true" :header-back="newAppBack" ref="head" />
    二维码已过期<br>请咨询教练获取最新二维码
  </div>
</template>
<script>
import { newAppBack, initBack } from '@/lib/appMethod'
import navBar from '@/components/nav-bar/nav-bar'

import headerMixin from '@/mixin/headerMixin'

export default {
  components: {
    navBar
  },
  mixins: [headerMixin],

  methods: {
    newAppBack,
  },

  async created() {
    initBack();
  },

}
</script>
<style lang="less" scoped>
.springAwakening2023_main {
  min-height: 100vh;
  background: #F5F5F5;
  text-align: center;
}</style>

<template>
  <div class="container">
    <!-- <nav-bar header-title="商推商城" /> -->
    <div class="">

      <div class="time-box">
        <van-field v-model="params.phone" autofocus center placeholder="请输入手机号" class="f32 fw6" style="margin: 16px 0;" />
        <van-field
          class="f32 fw6"
          v-model="params.code"
          @input="inputChange"
          center
          placeholder="请输入验证码"
        >
          <template #button>
            <div v-show="!isGetting" class="f28 fw6" @click="getSms">获取验证码</div>
            <van-count-down
              v-show="isGetting"
              @finish="timeFinish"
              :auto-start="false"
              :time="time"
              ref="countDown"
            >
              <template #default="timeData">
                <span class="block f28">重新发送{{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </template>
        </van-field>
      </div>
      <div
        @click="confirm"
        :class="['button', 'row-center-center', disabled ? 'disabled' : '']"
      >
        登录
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import Regular from '@/lib/regular'
import navBar from "@/components/nav-bar/nav-bar"

export default {
  components: {
    navBar,
  },
  data() {
    return {
      disabled: true,

      time: 60 * 1000,

      params: {
        phone: '',
        code: '',
      },
      isGetting: false,
      showConfirm: false,
    };
  },
  computed: {},
  async created() {
    // const token = localStorage.getItem('traderpush-token')
    // if (token) {
    //   this.$router.push('/data')
    // }
  },
  methods: {
    inputChange(v) {
      this.disabled = !v;
    },
    getSms() {
      // if (!Regular.isTelenum(this.params.phone)) {
      //   this.$toast('请输入正确的手机号')
      //   return
      // }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner",
      });
      this.$axios
        .post(this.baseURLApp + "/promoterData/sendPromoterDataLoginSms", {
          phone: this.params.phone,
        })
        .then((res) => {
          this.$toast("验证码已发送");
          this.$refs.countDown.start();
          this.isGetting = true;
        });
    },
    timeFinish() {
      this.$refs.countDown.reset();
      this.isGetting = false;
    },
    confirm() {
      // if (!Regular.isTelenum(this.params.phone)) {
      //   this.$toast('请输入正确的手机号')
      //   return
      // }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        overlay: true,
        loadingType: "spinner",
      });
      this.$axios
        .post(this.baseURLApp + "/promoterData/loginSmsVerify", this.params)
        .then((res) => {
          this.$toast.clear()
          const token = res.data.token
          axios.defaults.headers['token'] = token
          localStorage.setItem('traderpush-token', token)
          localStorage.setItem('traderpush-sysUserId', res.data.sysUserId)
          localStorage.setItem('traderpush-isLookAll', res.data.isAdmin)

          this.$router.push('/trader-push/list')
        });
    },

  },
};
</script>
<style lang="less" scoped>
.container {
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fff;
  padding: calc(130px + env(safe-area-inset-top)) 32px 32px;
  padding: calc(130px + constant(safe-area-inset-top)) 32px 32px;

  * {
    box-sizing: border-box;
  }

  .p1 {
    color: #6c727a;
    margin: 54px 0 58px;
  }

  .p2 {
    margin: 0 0 24px;
  }

  .p3 {
    margin: 0 0 72px;
  }
  .time-box {
    .van-cell {
      height: 100px;
      background-color: #f5f5f5;
      border-radius: 8px;
    }
    .van-count-down {
      min-width: 150px;
    }
    .block {
      color: #babbbb;
    }
  }
  .button {
    width: 686px;
    height: 96px;
    background: rgb(56, 117, 198);
    color: #fff;
    border-radius: 8px;
    margin: 176px 0 0;

    &.disabled {
      color: #9aa1a9;
      background: #dddddd;
    }
  }
}
</style>

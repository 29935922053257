<template>
  <div class="container">
    <div class="good-type col-start-center" v-for="type in packageList" :key="type.type">
      <p class="title f52 fw6">{{type.groupName}}</p>
      <div class="good-list">
        <div class="goods-model" v-for="(item) in type.commodities" :key="item.giftId">
          <div class="pub_full">
            <div class="goods-img"><img :src="item.imageUrl" /></div>
            <div class="pub_onefull">
              <div class="goods-name">{{ item.giftName }}</div>
              <div class="label-line">
                <template v-if="item.savePrice">
                  <div class="label-model" v-for="p in item.savePrice">
                    {{ p }}
                  </div>
                </template>

              </div>
              <div class="price-line">
                <span class="dl">¥</span>
                <span class="price">{{ item.salePrice }}</span>
                <span class="oldprice">¥{{ item.originPrice }}</span>
              </div>
            </div>
          </div>
          <div class="row-between-center line2">
            <div class="invalid-time row-start-center" @click="openRule(item)">
              <van-icon size="13" name="question-o" />
              <span class="f22">查看规则</span>
            </div>
            <div class="buy" :class="{ gray: !item.isBuy }" @click="buy(item)">立即购买</div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="tipShow" position="center" class="popsure">
      <div class="sure-title">提示</div>
      <div v-if="selectItem.cityNames" class="sure-content">您当前购买的自助健身卡、团课产品、私教体验券，仅限<span>{{
        `${selectItem.cityNames[0]}${selectItem.venueNames[0]}`
      }}</span>使用。因特殊活动，本产品购买后不可退，请确认后再付款。</div>
      <div class="sure-sub" @click="next">我知道了</div>
    </van-popup>
    <van-popup v-model="ruleShow" position="bottom" safe-area-inset-bottom class="pop-rule">
      <div class="pop-rule-title">
        {{ selectItem.title }}
        <van-icon name="cross" class="pop-rule-close" color="#fff" @click="ruleShow = false" />
      </div>
      <div class="pop-rule-content">
        <div v-html="selectItem.rule"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import {
  appPay,
  appPaySuccess,
  closeWindow,
  appToast, newAppBack
} from '@/lib/appMethod'
import { getParam } from '@/lib/utils'


export default {
  data() {
    return {
      payParams: { // 购买
        token: '',
        userId: '',
        venueId: 1,
        subject: '',
        originalPriceStr: '',
        totalAmount: '0.01', // 价格
        mouldId: 1004, // 商品的id
        type: "",
        title: '',
        whetherPrivacy: 0,
        extendsJson: { appVersion: '2.40.11' }
      },
      search: {
        userId: '',
        token: '',
        activityId: ''
      },

      tipShow: false,

      selectItem: {}, // 选中项
      ruleShow: false,

      packageList: [],
      titleConfig: {
        '14': 'https://img.chaolu.com.cn/ACT/newuser-2023/self-title.png',
        '10': 'https://img.chaolu.com.cn/ACT/newuser-2023/group-title.png',
      }
    };
  },

  mixins: [userMixin],

  async created() {
    await this.$getAllInfo(['userId', 'cityId'])

    this.payParams.token = this.search.token = this.token
    this.payParams.userId = this.search.userId = this.userId
    this.search.activityId = getParam().activityId

    this.getPackageList()

    appPaySuccess().then((res) => {
      appToast('支付成功')
      this.getPackageList()
    }).catch((res) => {
      appToast(res)
      this.getPackageList()
    })
  },
  mounted() {

  },
  methods: {
    newAppBack,
    getPackageList() {
      this.$axios.post(this.baseURLApp + '/newShop/commodites', this.search).then((res) => {
        this.packageList = res.data

        this.packageList.map((pack) => {
          pack.commodities.map((item) => {
            if (item.savePrice) item.savePrice = item.savePrice.split(',')
          })
        })
      })
    },
    openRule(item) {
      this.selectItem = item
      this.ruleShow = true
    },
    buy(item) {
      this.selectItem = item
      this.payParams.type = item.type
      if (!item.isBuy) return
      this.tipShow = true
    },
    //支付
    subOrder() {
      // 自定义加载图标
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 1000
      });

      this.payParams.mouldId = this.selectItem.giftId
      this.payParams.totalAmount = this.selectItem.salePrice
      this.payParams.subject = this.selectItem.giftName
      this.payParams.originalPriceStr = this.selectItem.originPrice
      // this.payParams.title = ''

      appPay(this.payParams, `/pages/webView/index?webUrl=${window.location.origin}/#/trader-push&userId=1&token=1&activityId=${this.search.activityId}`)

    },
    next() {
      this.tipShow = false
      this.subOrder()
    },

  },
};
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  background-color: #FF450C;
  padding: 64px 32px;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.good-type {
  width: 100%;

  .title {
    color: #fff;
  }

  .good-list {
    width: 100%;
    background-color: #EB3F0B;
    border-radius: 16px;
    padding: 28px 28px 0;
    margin: 40px 0 80px;
  }
}

.goods-model {
  background: #FCFDED;
  border-radius: 12px;
  margin-bottom: 28px;
  padding: 22px;
  min-height: 292px;

  .goods-img {
    width: 264px;
    height: 160px;
    margin-right: 22px;
    border-radius: 10px;
    overflow: hidden;
    flex-shrink: 0;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .goods-name {
    font-weight: bold;
    color: #242831;
    font-size: 28px;
    padding-top: 2px;
  }

  .label-line {
    margin: 12px 0;
    white-space: nowrap;

    .label-model {
      padding: 0 10px;
      height: 36px;
      background: #FF6040;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      margin-right: 12px;
      line-height: 1;
      display: inline-flex;
      align-items: center;

      .scale {
        transform: scale(0.9);
        transform-origin: 50% 50%;
      }
    }

    .limit {
      padding: 0;
      background: #79AF52;
    }
  }

  .price-line {
    .dl {
      font-size: 24px;
      color: #F03C18;
      font-weight: bold;
      margin-right: 4px;
    }

    .price {
      font-size: 48px;
      color: #F03C18;
      font-weight: bold;
      margin-right: 20px;
      position: relative;
      top: 2px;
    }

    .oldprice {
      text-decoration: line-through;
      color: #6C727A;
      font-size: 24px;
    }
  }

  .line2 {
    margin-top: 14px;

    .invalid-time {
      color: #3C454E;

      span {
        margin: 0 0 0 8px;
      }
    }

    .buy {
      width: 224px;
      height: 72px;
      background: linear-gradient(180deg, #F3BA5B 0%, #EE602E 100%);
      box-shadow: inset 0px 2px 4px 0px rgba(255, 255, 255, 0.4), inset 0px -2px 6px 0px rgba(255, 141, 77, 0.7);
      border-radius: 122px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 72px;
      font-size: 32px;
    }

    .gray {
      color: #6C727A;
      background: #DDDDDD;
      box-shadow: none
    }
  }
}

.popsure {
  width: 590px;
  border-radius: 12px;
  padding: 50px 40px;

  .sure-title {
    color: #242831;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
  }

  .sure-content {
    color: #3C454E;
    font-size: 24px;
    line-height: 1.6;
    margin: 36px 0 48px;
    text-align: center;

    span {
      color: #F03C18;
    }
  }

  .sure-sub {
    border-radius: 8px;
    background: #FFDE00;
    color: #000;
    font-weight: bold;
    font-size: 32px;
    line-height: 96px;
    text-align: center;
  }
}
.pop-rule {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        .pop-rule-title {
            height: 132px;
            color: #405817;
            font-weight: bold;
            background: linear-gradient(180deg, #FFEDD1 0%, #FFFDFC 100%);
            font-size: 32px;
            text-align: center;
            line-height: 130px;
            position: relative;

            .pop-rule-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

        .pop-rule-content {
            color: #405817;
            font-size: 26px;
            padding: 0 52px;
            max-height: 600px;
            overflow: auto;
            padding-bottom: 60px;

            div {
                margin-bottom: 46px;
                line-height: 1.6;
            }
        }
    }
</style>
